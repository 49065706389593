@import './_style.group.scss';
@import './_style.organisation.scss';
@import './_style.user.scss';

.kp-profile--view{
  .kp-toggle-bar.kp-profile--toggle-bar-primary{

    margin-top: 2rem;
    margin-left: 0;
    width: 100%;

    border: 0;
    padding: 0 2rem;

    @include media768(){
      padding: 0 6rem;

    }

    @include media992(){
      padding: 0;
      margin-left: 6rem;
      margin-top: -2rem;
      width: 50rem;
      transform: translateY(-100%);
      @include kp-multi-transition(transform);
      &.collapse-for-body{
        transform: translateY(0);
      }
    }


    .kp-toggle-bar__option{
      position: relative;
      background-color: $black-opacity50;


      &:not(.selected)::before{
        content: '';
        // background-color: green;
        width: 100%;
        height: 100%;
        position: absolute;
        border: 1px solid $white;
      }

      &:first-of-type{
        border-radius: 1rem 0 0 1rem;

        &:not(.selected)::before{
          border-radius: 1rem 0 0 1rem;
        }
      }

      &:last-of-type{
        border-radius: 0 1rem 1rem 0;

        &:not(.selected)::before{
          border-radius: 0 1rem 1rem 0;
        }
      }

      .kp-toggle-bar__option-text{
        color: $white
      }

      &.selected{
        background-color: $primaryColor100;
        outline: 0;
        border: 0;
      }
    }
  }
}
