.toggleButton-wrapper{
    display: flex;
    align-items: center;
    .toggleButton__label1, .toggleButton__label2{
        color: $greyColor40;

        &.-active-{
            // @include weight-bold();
            color: $greyColor100;
        }
    }
    .toggleButton{
        margin-left: 1rem;
        margin-right: 1rem;
        width: 4rem;
        height: 2rem;
        border-radius: 2rem;
        background-color: $primaryColor100;
        @include kp-multi-transition(background-color);
        position: relative;

        &:hover{
            cursor: pointer;
            background-color: $primaryColorText;

            .toggleButton__slider{
                border: 2px solid $primaryColorText;
            }
        }

        .toggleButton__slider{
            @include normalizeButton();
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            background-color: $white;    
            border: 2px solid $primaryColor100;
            position: absolute;
            top: 0;
            transition: left 0.2s ease-out, border 0.2s ease-out;

            &:hover{
                background-color: $white;   // important, else button seems to disappear     
            }
            

            &.-left-{
                left: 0;
            }

            &.-right-{
                left: 2rem;
            }
        }
    }
}


.toggleButton-wrapper{

    &.-inactive-{
        .toggleButton{
            background-color: $primaryColor40;
        }

        .toggleButton__slider{
            border: 2px solid $primaryColor40;
        }
    }

    &.-disabled-{
        .toggleButton__label1{
            color: $greyColor10
        }

        .toggleButton{
            background-color: $greyColor10;

            &:hover{
             
                background-color: $greyColor10;
    
                .toggleButton__slider{
                    border: 2px solid $greyColor10;
                }
            }
        }

        .toggleButton__slider{
            border: 2px solid $greyColor10;

            
        }

        
    }
}