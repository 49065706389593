.modalContent {
    // padding: 0 6rem;
    // padding: 0 2rem;
    .slectSubTypeTpl {
        padding: 2rem 6rem;
        background-color: $primaryColor10;
    }
    .tplBlocks {
        padding: 3rem 6rem 0rem 6rem;
        border-bottom: 1px solid $greyColor15;
    }
    .tplBlocks__component {
        padding-bottom: 5rem;
    }
    .blockError {
        background-color: rgba(241, 212, 212, 0.952);
    }
    .actionBtn-wrapper {
        width: 100%;
        height: auto;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
    }
}