// .kp-filters-container{
//   .kp-filters-group{

//     margin-left: -4rem;
//     margin-right: -4rem;

//     .kp-filter-wrapper{
//       @include kp-separator-right();
//       padding: 0 4rem;

//       &:last-of-type{
//         @include kp-no-separator();
//       }
//       .kp-dd__select{
//         padding: 0;

//         .kp-dd__select-text{
//           @include bodySans--L();
//         }
//       }
//     }
//   }

//   .kp-clear-filters-btn{
//     margin-top: 2rem;
//   }
// }


.OKE-Filters{
  
  .OKE-Filters__filters-wrapper{
    border-bottom: 1px solid $greyColor10;

    .OKE-Filters__filters-container{
      overflow: unset;
      position: relative;
      
      .OKE-Filters__filters-row{
        margin-left: -4rem;
        margin-right: -4rem;
    
        .OKE-Filters__filter-wrapper{
          padding-top: 2rem;
          border-right: 1px solid $greyColor10;
          position: relative;
          
          .OKE-Dropdown{
            .kp-labels-wrapper{
              padding-bottom: 0;
              padding-left: 4rem;

              .kp-label__text{
                color: $greyColor100 !important;
              }
            }

            .OKE-Dropdown__ref-wrapper{
              position: unset;

              .OKE-Dropdown__Select, .OKE-Dropdown__ComboboxSelect{
                padding-left: 4rem;
                padding-right: 4rem;
                background-color: $none;
                position: unset;
    
                &::after{
                  width:100%;
                  height: 100%;
                  top:0;
                  left:0;
                  background-color: $none;
                  z-index: -1;  
                  @include kp-transition(background-color); 
                }
    
                &:hover{
                  background-color: $none;  
    
                  &::after{
                    background-color: $greyColor5;
                  }
                }
              }
            }
            
          }
        }
      }

      

      .kp-input-container{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        //to get that cool searchbar expansion, but it is not really required.
        // width: 36rem;
        // @include kp-transition(width);
        // &.focussed{
        //   width: 100%;
        // }

        .kp-input-wrapper{
          height: 100%;
        }
      }
    }
  }

  .OKE-Filters__selectedFilters-wrapper{
    border-bottom: 1px solid $greyColor3;
    
    .OKE-Filters__selectedFilters-container{
      border-bottom: 1px solid $greyColor3;

      .OKE-Filters__selectedFilters{
        display: flex;
        flex-wrap: wrap;
        // height: 4rem;
        margin-top: -0.75rem;
        margin-bottom: -0.75rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        
    
        .OKE-Filters__selectedFiltersLabel{
          color: $greyColor40;
          display: flex;
          align-items: center;
          padding-right: 2rem;
          // line-height: 1.3em;
        }
    
        .OKE-Filters__selectedFilterTag{
          padding-right: 2rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
    
          // .OKE-Filters__selectedFilterTagText{
            
          // }
    
          .OKE-Filters__rmSelectedFilterBtn{
            @include normalizeButton();
            margin-left: 0.3rem;
            margin-bottom: 0.1rem;
          }
        }
      }
    }
  }

  .OKE-Filters__clearFiltersBtn{
    padding-top: 0;
    display: flex;
    align-items: center;
    .kp-link-content__text{
      @include label--M();
    }
  }
  
}
