//overrides on table elem and statusTag comp
.AdminParticipantRowExpandComp{
    .TableElem-Wrapper{
        overflow-x: unset;
        .TableElem__Header{
            background-color: $none;
            border: none;
            border-bottom: 1px solid $greyColor10;
            color: $greyColor40 !important;
        }

        .TableElem__Row-Wrapper{
            overflow: unset;
        }
        
        .TableElem__Row{
            background-color: $none;
            border: none;
            border-bottom: 1px solid $greyColor5;

            .statusTag-wrapper{
                .statusTag{
                    padding-left: 0;
                }
                
                &.success{
                    .statusTag{
                        background-color: $none;
                        color: $green;
                    }
                }
                &.neutral{
                    .statusTag{
                        background-color: $none;
                    }
                }
                &.danger{
                    .statusTag{
                        background-color: $red;
                    }
                }
            }
        }
    }
}