.kp-meta-secondary{
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .kp-meta-secondary__text{
    padding: 0 0.5rem;

    &.kp-meta-secondary__text--key{ color: $greyColor70; }
    &.kp-meta-secondary__text--value{ color: $primaryColorText; }
  }
}
