.kp-input-container {
  position: relative;
  // margin-bottom: 1rem;
  // width: 100%;
  
}
.kp-input-wrapper{
  // min-width: 36rem;

}
.kp-input {
  display: block;
  width: 100%;
  height: 5rem;
  border: 0;
  padding: 0; // to cancel out the native paddingleft
  background-color: $none;
  position: relative;
  z-index: 2;
  color: $greyColor100;
  &::placeholder {
    color: $greyColor40;
    text-transform: capitalize;
  }
  box-shadow: none;
}

.url-status--wrapper{
  position: absolute;
  top: -2.5rem;
  .url--status{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.kp-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  border-radius: 2px;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: $greyColor5;
  
  


  .kp-input-icon {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    .input__icon {
      @include svgColorSet($greyColor40);
    }
  }

  .kp-pwd-icon {
    position: absolute;
    right: 2rem;
    cursor: pointer;
    z-index: 3;
  }

  &.input--invert {
    background-color: $greyColor80;
    input {
      color: $greyColor5;
    }
    &:hover {
      background-color: $greyColor70;
    }
    .input__icon {
      @include svgColorSet($greyColor40);
    }

    &.focussed {
      background-color: $greyColor70;
    }
    &::after {
      background-color: $white;
    }
  }

  @include inputElements__hoverAndFocus();

  &.-has-error-{
    &::after{
      width: 100%;
      background-color: $red;
    }
  }

  .textInput--actionBtn {
    border-radius: 0 2px 2px 0;
    margin-right: -2rem;
  }
}

.kp-dynamic-search-input {
  margin-bottom: 0.5rem;
  .kp-input-wrapper {
    .kp-input-pseudo-wrapper {
      background-color: inherit;
      input {
        // padding: 0px !important;
        @include bodySans--L();
        &::placeholder {
          color: $primaryColor100;
        }

        &:hover {
          border: 0;
        }
      }

      &:hover {
        //  @include kp-transition(background-color);
        background-color: $greyColor10;
      }
    }
  }
}

//textarea
.kp-textarea {
  display: block;
  width: 100%;
  height: auto;
  padding: 2rem;
  border: 0;
  background-color: $greyColor5;
  color: $greyColor100;
  resize: none;

  &.contr {
    border: 0;
    padding: 1rem 0;
    background-color: $none;

    &.title {
      @include titleSerif--L();
    }

    &.subtitle {
      @include titleSerif--M();
    }
  }
}

//radio
.kp-radio-wrapper {
  display: flex;
  flex-wrap: wrap;
  user-select: none;

  .kp-radio {
    cursor: pointer;
    padding: 1rem;
    border: 1px solid $greyColor15;

    &.selected {
      background-color: $greyColor10;
    }
  }
}

.kp-radio-card-wrapper {
  user-select: none;
}

.kp-input--half-width-wrapper {
  @include media768() {
    .kp-input {
      width: 50%;
      padding-right: $halfGutter;
    }
  }
}

//override these overrides when in modal. not elegant. but we'll clean it later
.kp-modal-bg .kp-modal {
  .kp-input--half-width-wrapper {
    .kp-input {
      width: 100%;
      padding-right: 0;
    }
  }
}
