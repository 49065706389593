

.kp-admin-db-list-row{
  display: flex;
  position: relative;
  padding: 1rem;
  padding-right: 10rem;
  align-items: center;
  border-radius: 1.5rem;
  margin: 0.5rem 0;
  min-height: 5rem;

  .kp-admin-db-list-row__col{
    &.kp-admin-db-list-row__col--primary{
      display: flex;
      align-items: center;

      .kp-admin-db-list-row__col--primary__profile-img{
        margin-right: 1rem
      }

      .kp-admin-db-list-row__col--primary__user-role-badge{
        margin-left: 1rem
      }
    }

  }

  &.kp-table-row--has-row-actions{
    .kp-admin-db-list-row__col{
      &:last-of-type{
        padding-right: 5rem;
      }
    }
  }

  .kp-admin-db-list-row__edit-btn{
    position: absolute;
    right: 2rem;
  }

  .kp-admin-db-list-row__edit-actions{
    position: absolute;
    display: flex;
    right: 2rem;
  }
}
