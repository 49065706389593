.kp-like-contact-channels-input-group {
  margin: -0.5rem 0;

  .kp-like-contact-channels-input {
    padding: 0.5rem 0;
  }
}

.kp-like-contact-channels--display {
  
  border-radius: 1rem;

  .kp-like-contact-channels--display__exposed-link-group {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding-bottom: 1rem;
    
    .socialLink--button {
      height: 3rem;
      padding: 0;
      // justify-content: center;
      // align-items: center;
      // background-color: transparent;
      // color: $primaryColor40;
      // @include svgColorSet($primaryColor40);
    }
  }

  .kp-like-contact-channels--display__icons-group {
    display: flex;
    // justify-content: center;

    .button{
      border-radius: 0;
      margin-right: -2px;
      &:last-child{
        margin-right: 0;
      }
    }
    // .kp-icon-btn {
    //   padding: 0 0.2rem;
    // }
    // .button{
    //   margin: 1rem 0;
    //   border-radius: 0;
    //   border-color: $primaryColor40;
    //   @include svgColorSet($primaryColor40);
    //   // &:hover{
    //   //   background-color: transparent;
    //   // }
    // }
  }
}

.-show-text-icon- {
  display: none;
  @include mediaQuery("md") {
    display: flex;
  }
}
.-show-icon- {
  display: block;
  @include mediaQuery("md") {
    display: none;
  }
}


