//generic styling for any kind of contrSettings component
.kp-contribute-settings-wrapper{
  padding-bottom: 6rem;

  @include media768(){
    padding-bottom: 8rem;
  }

  &.kp-master-section--std-width{
    @include kp-body--std-width;
  }

  .kp-settings-block-wrapper{
    padding-top: 4rem;

    &.half{
      width: 50%;
      padding-right: 1rem;
    }

    &:first-of-type{
      padding-top: 0;
    }
  }
}

.OKE-TplConfig__langSelect{
  width: 20rem;
}

