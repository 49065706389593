.kp-labels-wrapper{
  padding-bottom: 1.5rem;

  .kp-label{
    color: $greyColor70;
    display: flex;
    align-items: center;
  }
  .kp-optional-label-tag{
    // padding-left: 1rem;
    color: $greyColor70;
  }

  .kp-error-label-tag{
    // padding-left: 1rem;
    color: $red;
  }

  .BlockLabel__sublabel{
    padding-top: 0.5rem; 
    color: $greyColor70;
  }
}
