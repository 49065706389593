.TableElem__RowExpandComp-wrapper {
    @include kp-transition(height);
}

.tableTags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tableTags__tag {
        margin-right: 1rem;
        padding: .5rem 1rem;
        border: 1px solid $greyColor15;
    }
}

.TableElem__RowCell{
    
    h1, h2, h3, h4, h5, h6{
        @include kp-ellipsis();
    }
    
    
    

}