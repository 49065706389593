
.Recoms-container{
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.RecomBlock {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include oke-negative-hor-margin('xs');
    @include oke-negative-hor-margin('sm');
    @include oke-negative-hor-margin('md');
    @include oke-negative-hor-margin('lg');

    @include pageContainer();

    @include mediaQuery('xxl'){
        @include oke-negative-hor-margin-0();
        @include pageContainer-0();    
    }
    
    .RecomBlock__label{
        padding-bottom: 1rem;
    }

    .RecomBlock__title{
        padding-bottom: 4rem;
    }

    .RecomBlock__recommendation{
        padding-bottom: 4rem;  
    }

    .RecomBlock__recommendation, .RecomBlock__checklist{
        .kp-rich-editor{
            @include bodySans__M();

            .li{
                @include bodySans__M();
                background-size: 1.7rem;
            }

            span{
                @include weight-normal(); //force normal weight
            }
        }
    }

    .kp-label__text{
        @include bodySans__M();
        @include weight-bold();
        @include t-case();
        @include ls-0();
        color: $greyColor100;
    }
}