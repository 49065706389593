.multiToggleInput{
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
  margin-bottom: -1rem;

  .multiToggleInput__button{
      @include normalizeButton();
      border: 1px solid $greyColor10;
      @include kp-multi-transition(background-color, box-shadow);
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;  
      height: 4rem; 
      @include bodySans__M();
      border-radius: 2px;
      padding: 1rem;
      @include multi-toggle-box-shadow-0();

      &:hover{
          background-color: $greyColor3;
      }

      &.-active-{
          @include multi-toggle-box-shadow();
      }

      .multiToggleInput__buttonContents{
          display: flex;
          // justify-content: center;
          align-items: center;

          .multiToggleInput__buttonColorTag{
              width: 2rem;
              height: 2rem;
              margin-right: 1rem;
          }
      }
  }
}