.statusTag-wrapper {
    display: flex; //
    .statusTag {
        @include weight-semibold();
        padding: 0 0.5rem;
        border-radius: 2px;
    }
    &.success {
        .statusTag {
            background-color: $lightGreen;
            color: $green;
        }
    }
    &.danger {
        .statusTag {
            background-color: $lightRed;
            color: $red;
        }
    }
    &.neutral {
        .statusTag {
            background-color: $lightYellow;
            color: $yellow;
        }
    }
}



.statusTag-wrapper.statusTag-wrapper--outlineStyle {
    .statusTag {
        @include h7();
        @include weight-normal();
        color: $white;
        background-color: $none;
    }
    &.success {
        .statusTag {
            border: 0.5px solid $green;
            color: $green;
        }
    }
    &.danger {
        .statusTag {
            border: 0.5px solid $red;
            color: $red;
        }
    }
    &.neutral {
        .statusTag {
            border: 0.5px solid $greyColor100;
            color: $greyColor100;
        }
    }
}