.DateRange-input-wrapper{
    border-radius: "2px";
    height: 50px;
    width: 306px;
    background-color: $greyColor5;
    display: flex;
    align-items: center;
}
  
.DateRange-input-divider{
    font-size: 2rem;
    line-height: 0;
    color: $greyColor40;
}
