.Card--UserProfile {
  position: relative;
  margin-bottom: 3rem;

  .Card-linkWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 22;
  }
  .Card__displayImg-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 22;
  }

  .Card__details {
    position: relative;
    margin-top: -5rem;
    padding: 0 2rem;
    padding-top: 7rem;
    border: 2px solid $greyColor5;
    background-color: $greyColor5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Card__title {
      width: 100%;
      text-align: center;
      word-wrap: break-word;
    }
    .Card__summary {
      margin: 2rem 0;
      margin-bottom: 4rem;
      width: 100%;
      text-align: center;
      font-style: normal;

      @include h5();
      @include kp-clamp-text(4);
    }

    @include kp-transition(background-color);

    &:hover {
      background-color: white;
    }
  }
}

.Card--Organisation {
  position: relative;
  margin-bottom: 3rem;

  .Card-linkWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 22;
  }
  .Card__displayImg-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 22;
  }

  .Card__details {
    position: relative;
    margin-top: -5rem;
    padding: 0 2rem;
    padding-top: 7rem;
    border: 2px solid $greyColor5;
    background-color: $greyColor5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Card__title {
      width: 100%;
      text-align: center;
      word-wrap: break-word;
    }
    .Card__summary {
      margin: 2rem 0;
      margin-bottom: 4rem;
      width: 100%;
      text-align: center;
      font-style: normal;

      @include h5();
      @include kp-clamp-text(4);
    }

    @include kp-transition(background-color);

    &:hover {
      background-color: white;
    }
  }
}

.Card--Group {
  position: relative;
  margin-bottom: 3rem;
  .Card-linkWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 22;
  }
  .Card__displayImg-wrapper {
    width: 100%;
    height: 8rem;
  }

  .Card__details {
    margin-top: 0;
    align-items: flex-start;
    position: relative;
    padding: 0 2rem;
    padding-top: 2rem;
    border: 2px solid $greyColor5;
    background-color: $greyColor5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .Card__title {
      text-align: start;
      word-wrap: break-word;
    }

    .Card__summary {
      width: 100%;
      text-align: start;
      margin: 2rem 0 1.5rem 0;
      font-style: normal;

      @include h5();
      @include kp-clamp-text(4);
    }
    .Card__members {
      width: 100%;
      font-size: 1.28rem;
      padding: 0 2rem 2rem 0rem;
    }

    @include kp-transition(background-color);

    &:hover {
      background-color: white;
    }
  }
}
