.kp-settings-modal-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black-opacity80;
  z-index: 10000;
  display: flex;
  justify-content: center;


  .kp-settings-modal{
    background-color: $white;
    overflow: scroll;

    .kp-settings-modal-header{
      display: flex;
      justify-content: space-between;

      .kp-settings-modal-header__right-comp{
        padding: 1rem;
      }
    }
  }
}
