@import '~bootstrap/scss/bootstrap.scss';

@import './sass/normalize.scss';
@import './sass/vars.scss';
@import './sass/fonts.scss';
@import './sass/grid.scss';
@import './sass/typesheet.scss';
@import './sass/global.scss';
@import './sass/static_pgs.scss';

// template
@import './components/organisms/ContributeTplBody/NEW/_style.scss';
@import './components/organisms/ContributeTplBody/NEW/forms_style.scss';
@import './components/organisms/ContributeTplBody/NEW/tplOverrides_style.scss';
@import './components/organisms/ContributeTplBody/NEW/updates_style.scss';
@import './components/organisms/ContributeTplBody/NEW/qs_and_recoms_style.scss';

//layout
@import './components/generalUI/PageContainer/style.scss';
@import './components/generalUI/SideScrollPageContainer/style.scss';


//comments
@import './components/comments/KPCommentsBlock/style.scss';

//notif
@import './components/notifications/NotifListItem/style.scss';
@import './components/notifications/NotifDropdown/style.scss';

//shells
@import './components/modals/SettingsModalShell/style.scss';

//modals
@import './components/modals/ModalShell/style.scss';
@import './components/modals/ModalHeader/style.scss';
@import './components/modals/KPInternalResSelect/style.scss';
@import './components/modals/KPResourceSelectPopUp/style.scss';

//share
@import './components/share/KPShareActions/style.scss';

//nav
@import './components/nav/PrimaryHeader/style.scss';

@import './components/nav/OverlayPageHeader/style.scss';
@import './components/nav/ProgressBar/style.scss';
@import './components/nav/HamburgerMenu/style.scss';
@import './components/nav/FullScreenMenuHeader/style.scss';
@import './components/nav/PageActionsBar/style.scss';
@import './components/nav/Footer/style.scss';

//generalUI
@import './components/generalUI/Accordion/style.scss';
@import './components/generalUI/ToggleButton/style.scss';
@import './components/generalUI/StatusTag/style.scss';
@import './components/generalUI/CheckboxElement/style.scss';
@import './components/generalUI/RadioInputElement/style.scss';
@import './components/generalUI/KPList/style.scss';
@import './components/generalUI/ContentBlockShell/style.scss';
@import './components/generalUI/KPButtons/style.scss';
@import './components/generalUI/Announcement/style.scss';
@import './components/generalUI/Alert/style.scss';
@import './components/generalUI/Tags__Intro/style.scss';
@import './components/generalUI/TabBar/style.scss';
@import './components/generalUI/ToggleBar/style.scss'; //phase out eventually
@import './components/generalUI/MetaBlock/style.scss';
@import './components/generalUI/TplMetaBlock/style.scss';
@import './components/generalUI/KPMetaPrimary/style.scss';
@import './components/generalUI/MetaSecondary/style.scss';
@import './components/generalUI/KPMetaSecondary/style.scss';
@import './components/generalUI/KPMetaHero/style.scss';
@import './components/generalUI/MetaBlock__Toolkits/style.scss';
@import './components/generalUI/MetaBlock__ToolsPage/style.scss';
@import './components/generalUI/Loader/style.scss';
@import './components/generalUI/SaveStatus/style.scss';
@import './components/generalUI/KPLink/style.scss';
@import './components/generalUI/KPLabel/style.scss';
@import './components/generalUI/KPMultiProfilesDisplay/style.scss';
@import './components/generalUI/KPTooltip/style.scss';
@import './components/generalUI/KPInlineAlert/style.scss';
@import './components/generalUI/KPUserRoleBadge/style.scss';
@import './components/generalUI/MarkdownParser/style.scss';

//inputs
@import './components/inputs/KPInputs/style.scss';
@import './components/inputs/LocationInput/style.scss';
@import './components/inputs/KPLikePlacesSearchPlusVillage/style.scss';
@import './components/inputs/KPDistrictBlockVillageField/style.scss';
@import './components/inputs/KPRadioCardBlock/style.scss';
@import './components/inputs/KPRichInput/style.scss';
@import './components/inputs/KPFAQInput/style.scss';
@import './components/inputs/KPFAQSingleBlock/style.scss';
@import './components/inputs/KPFAQComp/style.scss';
@import './components/inputs/KPRichEditor/style.scss';
@import './components/inputs/KPRadioInput/style.scss';
@import './components/inputs/MultiToggleInput/style.scss';
@import './components/inputs/KPCheckboxInput/style.scss';
@import './components/inputs/KPRichToolbar/style.scss';
@import './components/inputs/KPLinkInput/style.scss';
@import './components/inputs/KPCardLinkInput/style.scss';
@import './components/inputs/KPCardLinksDisplay/style.scss';
@import './components/inputs/Dropdowns/style.scss';
@import './components/inputs/KPDropdowns/style.scss';
@import './components/inputs/KPDropdownSelects/style.scss';
@import './components/inputs/KPDropdownOptions/style.scss';
@import './components/inputs/KPResourceUpload/style.scss';
@import './components/inputs/KPResourcesBlock/style.scss';
@import './components/inputs/KPLinkAsButtonInput/style.scss';
@import './components/inputs/KPVideoEmbed/style.scss';
@import './components/inputs/AudioEmbed/style.scss';
@import './components/inputs/KPPPTEmbed/style.scss';
@import './components/inputs/KPDynamicSearchInput/style.scss';
@import './components/inputs/KPDynamicSearchResultsBlock/style.scss';
@import './components/inputs/KPTagsInput/style.scss';
@import './components/inputs/KPDynamicProfileCardInput/style.scss';
@import './components/inputs/KPMultiProfileCardInput/style.scss';
@import './components/inputs/KPDynamicPlatformResourceInput/style.scss';
@import './components/inputs/KPDynamicPlatformResourceInputv2/style.scss';
@import './components/inputs/KPDateTimePicker/style.scss';
@import './components/inputs/KPLikeContactChannelsBlock/style.scss';
@import './components/inputs/KPLikeOrgAndDesignation/style.scss';
@import './components/inputs/KPLinkOrDownloadableInput/style.scss';
@import './components/inputs/KPTable/style.scss';

//filters
@import './components/discovery/FilterModule/style.scss';
@import './components/discovery/SearchFilter/style.scss';
@import './components/discovery/KPFilters/style.scss';
@import './components/discovery/SortElem/style.scss';

//cards
@import './components/cards/TableElem/_style.scss';
@import './components/cards/TableRowElem/_style.scss';
@import './components/cards/TableHeaderElem/_style.scss';
@import './components/cards/SkeletonCardLoader/_style.scss';
@import './components/loaders/TextLoader/_style.scss';
//content card --> content type specific styles
@import './components/cards/CardContent/_style.scss';
@import './components/cards/Card__Featured1/_style.scss';
@import './components/cards/Card__Featured2/_style.scss';
@import './components/cards/Card__Featured3/_style.scss';
@import './components/cards/Card__EventsSidebar/_style.scss';
@import './components/cards/ListElemContent/_style.scss';
@import './components/cards/CardEmbedContent/_style.scss';


@import './components/cards/KPAdminDbListRow/style.scss';


@import './components/cards/SubscribeCard/style.scss';
@import './components/cards/ProfileCard/style.scss';
@import './components/cards/ContentTypeCard/style.scss';
@import './components/cards/ExternalLinkCard/style.scss';

//hints
@import './components/hints/KPHintsBlock/style.scss';
@import './components/organisms/LoginForm/style.scss';
@import './components/organisms/RegisterForm/style.scss';

//adminDashboard
@import './components/adminDashboard/AdminRolesEdit/style.scss';
@import './components/adminDashboard/AdminNewsletterEdit/style.scss';
@import './components/adminDashboard/AdminDBSectionHeader/style.scss';
@import './components/adminDashboard/AdminTRDSync/style.scss';
@import './components/adminDashboard/AdminParticipantManager/style.scss';
@import './components/adminDashboard/AdminParticipantRowExpandComp/style.scss';




//images
// @import './components/images/KPImagesBlock/style.scss';
@import './components/inputs/KPImageBlock/style.scss';
@import './components/inputs/KPImageInput/style.scss';
@import './components/inputs/KPRichInlineImage/style.scss';
@import './components/generalUI/ProfileImgDisplay/style.scss';
@import './components/inputs/KPPDFDisplay/style.scss';


//data dashboard v2
@import './components/dataviz/LineChart/style.scss';
@import './components/dataviz/MapChart/style.scss';
@import './components/dataviz/OverviewModule/style.scss';
@import './components/dataviz/OverlayModule/style.scss';
@import './components/dataviz/DataFiltersBar/style.scss';
@import './components/dataviz/ComparisonModule/style.scss';
@import './components/dataviz/MapModule/style.scss';
@import './components/dataviz/PrimaryViewsBar/style.scss';
@import './components/dataviz/DatavizTooltips/style.scss';


//dataviz
@import './components/dataviz/KPDataSummaryCardHeader/style.scss';
@import './components/dataviz/KPDataSummaryCardShell/style.scss';
@import './components/dataviz/KPDataSummaryCard/style.scss';

//profile
@import './components/profile/ProfileSummarySidebar/style.scss';
@import './components/profile/KPProfileRelatedContent/style.scss';
@import './components/profile/ProfileBody/style.scss';

//kishan date test 
@import './components/inputs/DatePickerV2/styles';
@import './components/inputs/DateRangePickerV2/styles';



//organisms
@import './components/organisms/ChooseContentTypeBody/style.scss';
@import './components/organisms/ContributeConfigureBody/style.scss';
@import './components/organisms/FlexiContributeTplBody/style.scss';
@import './components/organisms/FeaturedCardSection/style.scss';
@import './components/organisms/HorizontalCardSection/style.scss';
@import './components/organisms/ResourceDownloadForm/style.scss';

@import './components/organisms/HomeCover/style.scss';
@import './components/organisms/HomeCoverV2/style.scss';
@import './components/organisms/PageCover/style.scss';
@import './components/organisms/KPNoResourcePlaceholder/style.scss';

@import './components/organisms/SidebarCardSection/style.scss';

//home tpls
@import './components/homeTpls/FrontPage/style.scss';
@import './components/homeTpls/FP_TPL__CHOOSE_CT/style.scss';
@import './components/homeTpls/FP_TPL__IFRAME/style.scss';

//tpl stylings
// @import './components/organisms/ContributeTplBody/style.scss';
// @import './components/organisms/ContributeTplBody/events_style.scss';
// @import './components/organisms/ContributeTplBody/tools_style.scss';
// @import './components/organisms/ContributeTplBody/stories_style.scss';
// @import './components/organisms/ContributeTplBody/activities_style.scss';
// @import './components/organisms/ContributeTplBody/resources_style.scss';
// @import './components/organisms/ContributeTplBody/initiatives_style.scss';
// @import './components/organisms/ContributeTplBody/news_style.scss';
// @import './components/organisms/ContributeTplBody/videoStories_style.scss';
// @import './components/organisms/ContributeTplBody/form_type_tpl_style.scss';

//admin db
@import './components/adminDashboard/KPAdminGroupRoleSettingPopUp/style.scss';

//pageTpls
@import './pageTpls/Login_Registration/style.scss';
@import './pageTpls/VerifEmail/style.scss';
@import './pageTpls/ContributeConfigure/style.scss';
@import './pageTpls/Search/style.scss';
@import './pageTpls/Subscribe/style.scss';
@import './pageTpls/ContributeTpl/style.scss';
@import './pageTpls/PublishedListing/style.scss';
@import './pageTpls/PublishedPage/style.scss';
@import './pageTpls/ViewProfile/_style.scss';
@import './pageTpls/AdminDashboard/style.scss';
@import './pageTpls/OKE404/style.scss';
@import './pageTpls/RecommendationsPage/style.scss';

@import './pageTpls/DataWQN/style.scss';
@import './pageTpls/DataPATH/style.scss';
@import './pageTpls/DataPATHDrilldown1/style.scss';

//community profile tpl stylings
@import './pageTpls/EditProfile/style.scss';
@import './pageTpls/EditProfile/wg_sn_style.scss';
@import './pageTpls/EditProfile/userProfiles_style.scss';
@import './pageTpls/EditProfile/org_style.scss';

@import './pageTpls/UserProfilesListing/style.scss';


@import './pageTpls/Playground/_style.scss';


// INDEX
@import './components/generalUI/TemplateIndex/style';

// userProfileCard

@import './components//cards/ProfileCards/_style.scss';

// updateTemplate
@import './components/contributionUpdate/UpdateTemplate/_style.scss';