.kp-container-fluid--admin-db-body{
  @include media768(){
    padding: 4rem 6rem;
  }
}

.kp-admin-db__table-header{
  padding: 0 $adminTableRowPadding;
  padding-bottom: 1rem;
  border-bottom: 1px solid $greyColor15;

}
