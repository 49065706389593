.radioInputElement{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: 2px solid $primaryColor100;
    margin-right: 1rem;
    background-color: $none;
    @include kp-transition-2(background-color, 0.2s); 

    .radioInputElement__fill{
      height: 0.8rem;
      border-radius: 50%;
      background-color: $white;
      flex: 1 0 0.8rem;
    }

    &.selected{
      background-color: $primaryColor100;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }