.kp-skeleton-card{
  overflow: hidden;
  position: relative;

  &::before{
    content: '';
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, $black-opacity03 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
    animation-iteration-count:infinite;
  }
}

@keyframes load {
    from {
        left: -100%;
    }
    to   {
        left: 100%;
    }
}
