.kp-rich-editor-wrapper {
  // padding: 3rem 0;
  position: relative;


  .kp-rich-editor__label-and-custom-placeholder-wrapper {
    position: relative;
    // background-color: $white;
    padding: 0 2rem;
    margin: 0 -2rem;

    .kp-rich-input__custom-placeholder {
      position: absolute;
      left: 2rem;
      bottom: 0;
      transform: translateY(100%);
      color: $primaryColorText;
      
    }
  }

  .kp-title{
    font-size: 3.2rem !important;
  }
  &.kp-story-subtitle {
    padding: 0;
  }



  //space after para for ul & ol (because no way to do this through blockStyleFn)
  .DraftEditor-root {

    ul,
    ol {
      @include rich-text-space-after();
    }
  }
}

.kp-editor-and-toolbar-wrapper {

  .kp-rich-format-toolbar__hints-and-toolbar-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 200;
    // wrote by rahul
    // display: flex;
    // justify-content: flex-start;
    width: 100%;

    @include media768() {
      position: sticky;
      bottom: 0;
    }
  }
}



//hints block
// .kp-rich-format-toolbar__hints-wrapper {
//   // position: fixed;
//   // bottom: 5rem;
//   // z-index: 1000;
//   // right: 0;
//   // width: auto;
//   // wrote by rahul
//   // position: absolute;
//   // top: 0;
//   // left: 0;
//   width: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 5rem;

//   @include media768() {
//     position: absolute;
//     // margin: -2.5rem -2rem;
//     width: auto;
//     bottom: 0;
//     right: 0;
//     height: 100%;
//     // margin-right: -2rem;
//     // transform: translateY(-50%);

//     // wrote byr rahul

//   }
// }

.temp-char-limit-alert {
  position: absolute;
  top: 0;
  right: 0;
  color: $red;
  z-index: 10;
}

.kp-inline-toolbar {
  position: absolute;
  // background-color: $greyColor100;
  transform: translate(-50%, -100%);

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    // background-color: $greyColor100;
    bottom: -5px;
    left: calc(50% - 5px);
    transform: rotate(45deg);
  }
}