.kp-modal__btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .kp-modal__btn{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
