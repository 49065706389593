@mixin date-input-style() {
  padding-left: 25px;
  background: $greyColor5;
  outline: none;
  border: none;
  font-size: 16px;
  width: 148px;
  height:50px;




}

.Date-input{
  @include date-input-style();
  &:hover{ background-color: $greyColor10 }

  &:focus{
    background-color: $greyColor10;
}
 
}

.Date-input-readOnly {
  @include date-input-style();
  pointer-events: none;
  // background: $greyColor10;
  font-weight: 500;


}






.Date-input-wrapper{

// @include inputElements__hoverAndFocus();


  &::after{
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $primaryColor100;
    @include kp-transition(width);
  }
  &:focus-within {
    background-color: $greyColor10;
    &::after{
      width: 100%;
  }
  }



}



.Date-input-container {
  position: relative;
  width: 300px;
  height: 50px;

  &:focus-within .calender-container{
      display: block;
  }
}



.calender-container {
 @include sans-serif;
 position: absolute;
 right:0;
 width: 300px;
  height: 319px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  display: none;
  z-index: 10000;
  

  .header {
    display: flex;
    width: 90%;
    margin: auto;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      font-size: 16px;
      width: calc(280px / 7);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      // border: 6px 0px $white;
      border-top: 6px solid $white;
      border-bottom: 6px solid $white;
    

      transition: background-color 0.2s;
      &:hover:not(.disabled-date){
       background-color: $primaryColor100;
       color: $white;
        cursor: pointer; 
      }
      
      
    }
  }
}



::placeholder {
  color: $greyColor40 !important;
  font-size: 16px;
}

.weekdays {
  display: flex;
  justify-content: center;
  height: 35px;
  div {
    font-size: 16px;
    font-weight: bold;
    opacity: 0.7;
    width: calc(280px / 7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.disabled-date {
  opacity: 0.5;
}
.range{
  background-color: $primaryColor10;
  
}


.today {
  
  background-color: $primaryColor100;
  color:$white;
}
