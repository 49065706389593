
.Home__featured-container{

  @include mediaQuery('sm'){
    padding-top: 6rem;
  }

  @include mediaQuery--lessThan('sm'){
    padding: 0;
  }

  .Home__featuredSidebar{
    @include mediaQuery('lg'){
      margin-right: -4rem;
    }
    @include mediaQuery('xl'){
      margin-right: -6rem;
    }
    @include mediaQuery('xxl'){
      margin-right: 0;
    }
  }
}



.kp-container-fluid--featured-content {
  padding-right: 0;

  // @include media768(){
  //   padding-top: 6rem;
  //   padding-bottom: 6rem;
  // }
}

.featured-sections-wrapper {
  @include media768() {
    padding-left: 6rem;
  }
}

.event-card-sections-wrapper {
  background-color: #E0F0FD;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow: auto;

  .kp-pane-heading {
    margin: 3rem;
    // margin-bottom: 0;
  }

  a {
    margin-left: 1rem;
  }
}



.fb-group{
  width: 100%;
  display: flex !important;
  justify-content: center;
}