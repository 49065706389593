.HomeCover{
  display: flex;
  height: calc(100vh - 6rem);
  position: relative;

  @include mediaQuery('sm'){
    height: 60rem;
  }

  .HomeCover__img{
    background-size: cover;
    background-position: center;
    
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    @include kp-transition(opacity);
  }

  .HomeCover__img-wrapper{
    position: relative;
    
  }
  .HomeCover__img2-wrapper{
    width: 50%;
  }

  .HomeCover__img1-wrapper{
    width: 100%;
    margin-top: 5rem;
    
    height: 0;
    padding-bottom: 130%;

    @include mediaQuery('md'){
      width: 50%;
    }

    @include mediaQuery('sm'){
      margin-top: 0;  
      height: auto;
      padding-bottom: unset;

    }
  }

  .HomeCover__title{
    position: absolute;
    left: 0;
    top: 0;
    width: 22rem;
    background-color: $primaryColor100;
    padding: 5rem;
    color: $white;
    z-index: 100;

    @include mediaQuery('sm'){
      left: 6rem;
    }

    @include mediaQuery('lg'){
      width: 26rem;
    }
  }

  .HomeCover__desc{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    // background-color: $white;
    
    padding: 2rem;

    @include mediaQuery('md'){
      left: 50%;  
      right: unset;
    }

    @include mediaQuery('sm'){
      padding: 0;
      right: 6rem;
      width: 40rem;
    }

    .HomeCover__descContents{
      padding: 5rem;
      background-color: $greyColor3;
      position: relative;
    }
  }
  
  .HomeCover__cta{
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0; //override


  }
}