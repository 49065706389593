.Card-Wrapper--Featured1{

  .Card--Featured1{
    cursor: pointer;

    &:hover{     
      .Card__title{
        color: $primaryColorText;
      }

      .Card__contentModule-2 .Card__title .Card__title-span{
        //animate the underline created by the mixin : underlineForAnimation
        background-size: 100% 100%;
      }
    }

    .Card__contentModule-1{

      .Card__image-container, .Card__video-container{
        height: 0;
        padding-top: 46%;
        overflow: hidden;
        position: relative;
      
        .kp-image-input, .kp-video-embed {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
        }
      }
    }
  
    .Card__contentModule-2{
      padding: 2rem;

      .Card__title{
        @include kp-clamp-text(4);
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        @include kp-transition(color);

        .Card__title-span{
          @include underlineForAnimation(5, 0.5s)
        }
      }
    }   

  }
  
}

