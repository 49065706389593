@import './gridVars.scss';

//breakpoint handling
//MOBILE FIRST
//whichever breakpoint you want to break at, specify it in the brackets
@mixin mediaQuery($sizeInitials) {
  @media (min-width: map-get($breakpoints, $sizeInitials)) {
    @content;
  }
}

@mixin mediaQuery--lessThan($sizeInitials) {
  @media (max-width: map-get($breakpoints, $sizeInitials)-1) {
    @content;
  }
}


//margin setting
@mixin pageContainerFluid(){
  padding-left: map-get($margins, 'xs');
  padding-right: map-get($margins, 'xs');

  @include mediaQuery('sm'){
    padding-left: map-get($margins, 'sm');
    padding-right: map-get($margins, 'sm');
  }
  
  @include mediaQuery('xl'){
    padding-left: map-get($margins, 'xl');
    padding-right: map-get($margins, 'xl');
  }
}

.pageContainerFluid{ @include pageContainerFluid() }

@mixin pageContainer(){
  @include pageContainerFluid();
  max-width: map-get($breakpoints, 'xxl' );
  margin-left: auto;
  margin-right: auto;
  // overflow: hidden;
}

.pageContainer{ @include pageContainer() }

//--

@mixin pageRow(){
  display: flex;
  flex-wrap: wrap;
  margin-left: -(map-get( $gutter, 'md' )/2 );
  margin-right: -(map-get( $gutter, 'md' )/2 );

  @include mediaQuery('xl'){
    margin-left: -(map-get( $gutter, 'xl' )/2 );
    margin-right: -(map-get( $gutter, 'xl' )/2 );
  }
}

.pageRow{ @include pageRow() }


@mixin gutter(){
  padding-left: map-get( $gutter, 'md' )/2 ;
  padding-right: map-get( $gutter, 'md' )/2 ;

  @include mediaQuery('xl'){
    padding-left: map-get( $gutter, 'xl' )/2 ;
    padding-right: map-get( $gutter, 'xl' )/2 ;
  }
}

//pass:
//1 : num cols you wanna span
//2 : breakpoint where you want this setting to activate
@mixin oke-col($i, $breakpoint){
  @include gutter(); //gutter has its own setting as to when it should break irrespective of the current col config  

  @include mediaQuery($breakpoint){
    width: percentage( ( 1/map-get($columns, $breakpoint) )*$i ); //map-get(cols, breakpoint) === basically gets the tot num of columns for this particular breakpoint, i.e md, lg, xl OR xxl
  }
}

@mixin oke-offset($i, $breakpoint){
  @include mediaQuery($breakpoint){
    margin-left: percentage( ( 1/map-get($columns, $breakpoint) )*$i ); //map-get(cols, breakpoint) === basically gets the tot num of columns for this particular breakpoint, i.e md, lg, xl OR xxl
  }
}

@mixin oke-negative-hor-margin($breakpoint){
  @include mediaQuery($breakpoint){
    margin-left: -(map-get($margins, $breakpoint)); //map-get(cols, breakpoint) === basically gets the tot num of columns for this particular breakpoint, i.e md, lg, xl OR xxl
    margin-right: -(map-get($margins, $breakpoint));
  }
}

// mixin can be used directly as : e.g : @include oke-col(4, 'xl');

//generate all the possible classes to create the different cols for the different breakpoints
@mixin oke-col-classes(){
  @each $key, $value in $breakpoints { //key === breakpoint initials (e.g. xl, md etc.)   |   value === breakpoint pixels (e.g 1300ox etc)
    @for $i from 1 through map-get($columns, $key) { //map-get(cols, breakpoint) === basically gets the tot num of columns for this particular breakpoint, i.e md, lg, xl OR xxl
      .oke-col-#{$key}-#{$i} { 
        @include oke-col($i, $key)
      }

      .oke-offset-#{$key}-#{$i} { 
        @include oke-offset($i, $key)
      }

      .oke-negative-hor-margin-#{$key} {
        @include oke-negative-hor-margin($key )
      }

    }
  }
};

//resets
@mixin oke-col-0(){ width: 100% }

@mixin oke-negative-hor-margin-0(){
  margin-left: 0;
  margin-right: 0;
}

@mixin pageContainer-0(){
  padding-left: 0;
  padding-right: 0;
}

.oke-col{ @include oke-col-0() }
@include oke-col-classes;


//naming system: 

//bootstrap: col-xl-6;

//oke: oke-col-xl-6;


//card1: // oke-offset-xl-3 oke-col-xl-3
//card2: // oke-col-xl-3
//card3: // oke-col-xl-3
//card4: // oke-col-xl-3
 