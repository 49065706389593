// .kp-image-input-wrapper{

  .kp-image-input{
    position: relative;

    .kp-image-input__label{
      color: $primaryColorText;
    }

    .kp-image-input__sublabel{
      color: $greyColor70;
    }

    .ImageInput__imgThumbnails{
      position: absolute; 
      top: 1rem;
      left: 1rem;
      display: flex;
      margin: 0 -2px;

      .ImageInput__imgThumbnail{
        width: 40px; 
        height: 40px;
        background-size: cover;
        background-position: center;
        margin: 0 2px;
        cursor: pointer;
      }
    }

    .ImageInput__content-flexWrapper{
      @include kp-transition(transform);
    }
  }
// }

.kp-image-input__placeholder-block{
  @include imageBlockPos();

  .kp-image-input__placeholder-block-content{
    @include imageBlockContentPos();
    height: 100%;
    width: 100%;
    padding: 2rem;
    background-color: $primaryColor10;
    display: flex;
    align-items: center;
    justify-content: center;

    .kp-image-block__placeholder-initial{
      color: $primaryColorText;
    }

    .kp-image-upload-btn-wrapper{
      border: 1px dashed ;

    }

  } //end kp-image-input__content
} //end kp-image-input__content-wrapper



.kp-image-upload-btn-wrapper{
  position: relative;
  height: 100%;
  width: 100%;

  .kp-file-upload-btn-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .sublabel{
      text-align: center;
    }
  }
}
