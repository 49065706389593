.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  // margin: 1rem;
  border-radius: 2px;
  height: 5rem;
  z-index: 110;
  border: none;
  @include buttonText();
  &.disabled{
    cursor: not-allowed;
  }
}

.button--primary {
  border: 2px solid $primaryColor100;
  box-shadow: -4px 4px 1px $lightShadowColor;
  background-color: $none;
  color: $primaryColorText;
  @include kp-transition(background-color);
  
  .button_icon {
    @include svgColorSet($primaryColorText);
  }
  
  &:hover {
    background-color: $primaryColor40;
    
  }

  &.disabled {
    color: $greyColor40; 
    border: 2px solid $greyColor15;
    box-shadow: -4px 4px 1px $darkShadowColor;
  }
  &.disabled:hover {
    background-color: $none;
  }

  &.button--invert {
    color: $white;
    border: 2px solid $white;
    box-shadow: -4px 4px 1px $darkShadowColor;
    background-color: $none;
    
    .button_icon {
      @include svgColorSet($white);
    }

    &:hover {
      background-color: $greyColor80;
    }
    &.disabled {
      color: $greyColor40;
      border: 2px solid $greyColor70;
      box-shadow: -4px 4px 1px $disabledShadowColor;
    }

    &.disabled:hover {
      background-color: $none;
    }
  }
}

.button--secondary {
  border: none;
  background-color: $primaryColor100;
  color: $white;
  @include kp-multi-transition(color, background-color);

  .button__icon {
    @include svgColorSet($white);
  }

  &:hover {
    background-color: $primaryColor40;
    color: $primaryColorText;
    
    .button__icon {
      @include svgColorSet($primaryColorText);
    }
  }

  &.disabled {
    background-color: $greyColor15;
    color: $greyColor70;

    .button__icon {
      @include svgColorSet($greyColor70);
    }
  }
  // &.disabled:hover {
  //   background-color: $greyColor15;
  //   color: $greyColor70;
  // }

  &.button--invert {
    color: $greyColor100;
    background-color: $white;
    
    .button__icon {
      @include svgColorSet($greyColor100);
    }

    &:hover {
      background-color: $greyColor40;
      color: $white;
      .button__icon {
        @include svgColorSet($white);
      }
    }
    &.disabled {
      background-color: $greyColor15;
      color: $greyColor70;
      .button__icon {
        @include svgColorSet($greyColor70);
      }
    }

    // &.disabled:hover {
    //   color: $greyColor70;
    //   background-color: $greyColor15;
    // }
  }
}

.button--tertiary {
  border: 2px solid $primaryColor100;
  color: $primaryColor100;
  background-color: $none;
  @include kp-transition(background-color);

  &:hover {
    background-color: $primaryColor10;
  }

  &.-active- {
    background-color: $primaryColor10;
  }
  
  &.disabled {
  
    border: 2px solid $greyColor40;
    color: $greyColor40;
  
    .button__icon {
      @include svgColorSet($greyColor40);
    }
  }
  
  &.disabled:hover{
    background-color: inherit;
  }

  &.button--invert {
    border: 2px solid $white;
    color: $white;
    background-color: $greyColor100;
    &:hover {
      background-color: $greyColor80;
    }
    &.disabled{
      border: 2px solid $greyColor40;
      color: $greyColor40;
    }
    &.disabled:hover{
      background-color: inherit;
    }
  }
}

.button--ghost {
  color: $primaryColorText;
  background-color: $none;
  @include kp-transition(background-color);

  &.-only-icon-{
    position: relative;

    &::before{
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: $none;
      @include kp-multi-transition(width, height, border-radius, background-color);
      z-index: -1;
    }

    &:hover{
      &::before{
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background-color: $primaryColor10;
      }
    }
  }

  &:not(.-only-icon-){
    &:hover { background-color: $primaryColor10 }
  }

  &.disabled {
    color: $greyColor40;
    .button__icon {
      @include svgColorSet($greyColor40);
    }
  }
  &.disabled:hover{
    background-color: transparent;
  }
  
  &.button--invert {
    color: $white;
    background-color: $greyColor100;

    &.-only-icon-{
      position: relative;
  
      &:hover{
        &::before{
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background-color: $greyColor80;
        }
      }
    }
    
    &:not(.-only-icon-){
      &:hover {
        background-color: $greyColor80;
      }
    }

    
    &.disabled{
      color: $greyColor40;
    }
    // &.disabled:hover{
    //   background-color: transparent;
    // }
  }
}



// .disabled {
//   color: $greyColor40;
//   cursor: not-allowed !important;
//   border: 2px solid $greyColor15;
//   box-shadow: -4px 4px 1px rgba(175, 175, 175, 0.6);
//   &:hover {
//     // color: $greyColor70;
//     background-color: transparent;
//   }
// }

.kp-btn,
.kp-icon-btn {
  border: 0;
}

.kp-btn-wrapper {
  display: flex;
  justify-content: center;
}

.kp-btn {
  position: relative;
  color: $primaryColorText;
  cursor: pointer;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-radius: 1rem;
  border: 2px solid $primaryColor100;

  overflow: hidden;
  @include buttonText();

  .kp-btn__icon-wrapper {
    display: flex;
    align-items: center;
  }

  @include kp-transition(background-color);
  &::before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
    // background-color: $primaryColor100;//default color if no type is defined
  }

  &::after {
    content: "";
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
    background-color: $black-opacity05; //default hover color if no type is defined

    @include kp-transition(width);
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  &.primary {
    background-color: $none !important;
    color: $primaryColorText;
    box-shadow: -4px 4px 1px $primaryColor40;

    &::before {
      // background-color: $primaryColor100;
    }
  }

  &.secondary {
    background-color: $none;
    color: $primaryColorText;

    &::before {
      // background-color: $primaryColor100;
    }
  }

  &.tertiary {
    color: $primaryColorText;
    background-color: $none;

    &::before {
      background-color: $none;
    }
  }

  @include custom-btn-types();

  &.large {
    height: 5rem;
    padding: 0 4rem;
  }

  &.small {
    height: 4rem;
    padding: 0 2rem;
  }

  &:disabled,
  &.disabled {
    color: $greyColor70 !important;
    // background-color: $white !important;
    cursor: not-allowed;
    border: 2px solid $greyColor15 !important;

    &.primary {
      box-shadow: -4px 4px 1px $greyColor10;
    }

    &.tertiary {
      background-color: $none !important;

      &::before {
        background-color: $none !important;
      }

      &::after {
        background-color: $none !important;
      }

      &:hover {
        background-color: $none !important;
      }
    }

    &::before {
      background-color: $white !important;
    }

    &::after {
      background-color: $greyColor10 !important;
    }

    &:hover {
      color: $greyColor70 !important;
      background-color: $greyColor10 !important;
    }
  }
}

.kp-icon-btn {
  position: relative;
  // border-radius: 50%;
  cursor: pointer;
  padding: 0;
  height: auto;
  // wrote by rahul  > 4-> 5
  width: 4rem;
  height: 4rem;

  &.small {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .kp-icon-btn__child-wrapper {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 1;
    @include kp-multi-transition(background-color);
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $black-opacity05;
    border-radius: 50%;
    z-index: 2;
    @include kp-multi-transition(height, width, border-radius);
  }

  &:hover {
    &::after {
      width: 100%;
      height: 100%;
    }
  }

  //over-ride to square shape, for square propped buttons
  &.square {
    &::before {
      border-radius: 0;
    }

    &::after {
      border-radius: 50%;
    }

    &:hover {
      &::after {
        border-radius: 0;
      }
    }
  }

  &:disabled {
    background-color: $greyColor10 !important;
    cursor: not-allowed;

    &.tertiary {
      background-color: $none !important;
    }

    &:hover {
      &::after {
        width: 0;
        height: 0;
      }
    }
  }

  &.primary {
    background-color: $none;

    &::before {
      background-color: $primaryColor100;
    }

    // &::after{ background-color: $primaryColor100-shadow }
  }

  &.secondary {
    background-color: $none;

    &::before {
      background-color: $primaryColor100;
    }

    // &::after{ background-color: $primay-color-5-shadow }
  }

  &.tertiary {
    // width: 3.8rem;
    // height: 3.8rem;
    background-color: $none;
    // &::before{ background-color: $primaryColor100 }
    // &::after{ background-color: $greyColor10 }
  }

  &.danger {
    background-color: $none;

    &::before {
      background-color: $red;
    }
  }
}

.kebabMenuBtn {
  width: 3rem !important;
  height: 5rem !important;
  border-radius: 0 !important;
}

.kp-file-upload-btn-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.kp-link-as-button-input__display-btn {
  color: $white;
}

.kp-file-upload-btn {
  color: $white;
}

.kp-join-community-btn {
  color: $primaryColorText;
}


