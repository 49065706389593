.template-index {
  position: absolute;
  width: 25rem;
  left: 6rem;
  // background-color: #333;
  // border-top: 1px solid salmon;

  &--content {
    position: sticky;
    top: 0;
    padding-top: 1rem;
    max-height: 100vh;


    .index-link {
      font-size: 12.8px;
      display: block;
      margin-bottom: 1rem;
      text-transform: capitalize;
      
    }
  }
}

.MobileIndex {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;

  background-color: #e77f7f;
  z-index: 190;
  &__visible {
    cursor: pointer;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 1rem;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $greyColor100;
    border-top: 1px solid #e1e1e1;
    @include pageContainer();

    &-label {
      text-transform: capitalize;
      font-size: 1.4rem;
      color: #e1e1e1;
    }
    .lebel-text {
      font-size: 12.8px;
      font-weight: bold;
      font-family: "noto_sans";
      color: #e1e1e1;
    }
  }

  &--content {
    
    @include pageContainer();
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: height 300ms ease;
    // height: 400px;
    background-color: $greyColor100;
    .index-link{
      color: white;
      font-size: 14px;
      padding-bottom: 1.5rem;
      text-transform: capitalize;
    }
  }
}
