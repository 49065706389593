.kp-progress-bar{
  background-color: $greyColor10;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;

  .kp-progress-bar__text-wrapper{
    width: 100%;
    max-width: 90rem;
    text-align: center;
  }
}
