.kp-profile--edit--workingGroups,
.kp-profile--view--workingGroups,
.kp-profile--edit--stateNetworks,
.kp-profile--view--stateNetworks{

  .kp-tpl-block{

    &.kp-tpl-block--formed-in--month{
      padding-bottom: 0.5rem;
      display: inline-block;
    }

    &.kp-tpl-block--formed-in--year{
      padding-top: 0.5rem;
      display: inline-block;
    }

  }
}
