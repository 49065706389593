.kp-static-pg{
  padding-top: 0;
  padding-bottom: 0;
  background-color: $white;

  @include media768(){
    background-color: $greyColor5;
  }

  .kp-static-pg__content-container{
    background-color: $white;
    padding: 6rem 0;
    @include box-shadow-1();

    @include media768(){ padding: 6rem }

    .kp-static-pg__content-wrapper{
      // margin-top: -2rem;
      // margin-bottom: -2rem;
      padding-bottom: 6rem;

      &:last-child{
        padding-bottom: 0;
      }
    }
  }

  .kp-static-pg__text-block{
    padding-top: 2rem;
    padding-bottom: 2rem;

    &:first-child{
      padding-top: 0;
    }

    &:last-child{
      padding-bottom: 0;
    }

    &.kp-static-pg__text-block--title{
      padding-bottom: 0;
    }
  }

  .kp-static-pg__list-block{
    padding-left: 1rem;
    padding-top: 1rem;

    li{
      list-style: disc;
      padding-top: 1rem;

      &:first-of-type{
        padding-top: 0;
      }
    }
  }
}

.kp-static-pg__section-divider{
  border-bottom: 1px solid $greyColor15;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
