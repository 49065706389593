.kp-hamburger-menu {
  position: fixed;
  top: 0;
  background-color: $greyColor100;
  width: 100%;
  height: 100vh;
  left: 0;
  overflow-y: scroll;

  display: block;
  z-index: 100000000;
  @include kp-transition(margin-left);

  @include media992() {
    display: none
  }

  &.open {
    margin-left: 0;
  }

  &.closed {
    margin-left: -100%;
  }

  .kp-hamburger-menu__header{
    position: sticky;
    top: 0;
    background-color: $greyColor100;
    border-bottom: none;
    z-index: 1000;
  }

  .HamburgerMenu__body-container{
    overflow-y: scroll;

    .HamburgerMenu__linkSection{
      padding-top: 2rem;
      padding-bottom: 2rem;

      &.HamburgerMenu__publishBtnSection{
        border-bottom: 1px solid $greyColor80;
        padding-bottom: 4rem;
        margin-bottom: 2rem;
      }
  
      &:first-of-type{
        padding-top: 4rem;
      }
  
      &:last-of-type{
        padding-bottom: 4rem;
      }
  
      .HamburgerMenu__linkSectionTitle{
        color: $greyColor40;
        padding-bottom: 1rem
      }
  
      .navLink{
        padding-bottom: 1rem;
        .navLink__link{
          color: $white;
          @include titleSans--S(); 
          padding-bottom: 1rem;
  
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }

  

  

  .kp-hamburger-menu__pageActionBar {
    position: absolute;
    padding: 0 2rem 2rem 2rem;
  }

  .kp-hamburger-menu__body {
    position: absolute;
    top: 0;
    height: 100vh;
    padding: 6rem 2rem 10rem 2rem;
    width: 100vw;

    .kp-hamburger-menu-body-content-wrapper {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;

      .kp-hamburger-menu__links--primary {
        >li {
          list-style: none;
        }
      }

      .kp-hamburger-menu__link--primary {
        margin-top: 1.5rem;


        .kp-link {
          color: $primaryColor10;

          .kp-link-content__icon {
            display: flex;
          }
        }
      }


      ul {
        margin-top: 3rem;
      }

      .kp-hamburger-menu__link--secondary {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      ul {
        margin-top: 3rem;
      }
    }
  }
}



.kp-hamburger-menu__body--noScroll {
  overflow: hidden;
}

.kp-hamburger-menu__body--noScroll {
  overflow: hidden;
}