.CardEmbed-Wrapper--Content{
  padding-top: 1rem;
  padding-bottom: 1rem;

  .CardEmbed--Content{
    padding: 3rem 2rem;
    // padding-bottom: 3rem;  
    display: flex;
    cursor: pointer;
    position: relative;
    background-color: $greyColor5;
    @include kp-multi-transition(background-color);

    &::before{ //dont remember exactly why the border was separated from the card, but it was causing some conflict i remember
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $greyColor5;
      z-index: 1;
    }

    &:hover{     

      background-color: $white;
      // .CardEmbed__title{
      //   color: $primaryColorText;
      // }

      // .CardEmbed__title .CardEmbed__title-span{
      //   //animate the underline created by the mixin : underlineForAnimation
      //   background-size: 100% 100%;
      // }
    }
  }

  
  

  .CardEmbed__contentModule-1{
    display: flex;
    align-items: center;
    
    
  }

  .CardEmbed__contentModule-2{
    .CardEmbed__action{
      position: absolute;
      top: 0;
      right: 0;
    }
  }



  .CardEmbed__label{
    padding-bottom: 1rem;
  }

  .CardEmbed__date{
    padding-bottom: 0.5rem;
    .cardDateText {
      @include bodySans--L();
      @include weight-bold();
    }
    
  }

  .CardEmbed__title{
    padding-bottom: 1rem;
    // @include kp-transition(color);

    // .CardEmbed__title-span{
    //   @include underlineForAnimation(5, 0.5s)
    // }
  }

  

  .CardEmbed__metaBlock{
    padding: 0;
  }

  .CardEmbed__image-container, .CardEmbed__video-container{
    width: 70px;
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-right: 2rem;

    //exception media query situation - 1 of 1
    @media (max-width: 399px) {
      width: 6rem;
      height: 6rem;
    }
  
    .kp-image-input, .kp-video-embed {
      width: 140px
    }
  }

}


