

.kp-login-registration-page-header {
  display: block;
  @include media992() {
    display: none;
  }
}


.kp-login-registration-page {

  padding: 2rem;
  min-height: 100vh;
  position: relative;
  // position: absolute;
  // margin-top: -$primaryHeaderHeight_D;
  top: 0;
  width: 100%;
  z-index: 1000000;
  // background-color: $white;
  background-color: $primaryColor10;
  display: block;

  @include media576() {
    padding: 6rem;
  }

  @include media992() {
    display: flex;
    margin-top: 0;
    z-index: 0;
    background-color: $primaryColor10;
    min-height: calc(100vh - 6rem);
    margin-top: 0!important;  //cancelling out the negative margin of kp-overlay-page class

  }

  .kp-input-wrapper{
    min-width: unset;
  }

  .kp-login-registration-page__card-wrapper{
    height: auto;
    width: 100%;
    z-index: 100;
    flex: 0 0 auto;

    @include media992(){ width: auto }

    .kp-login-registration-page__card{
      padding: 4rem 2rem;
      background-color: $white;
      width: 100%;
      @include box-shadow-1();

      @include media576(){
        padding: 4rem;
      }
    }

    .kp-login-registration-page__card--login {

      @include media992(){
        width: 45rem;
        transition: 0.3s;
      }
    }
    .kp-login-registration-page__card--register {
      background-color: white;

      @include media992(){
        width: 90rem;
        // border-top: none;

        transition: 0.3s;
      }
    }
    .kp-login-register-form__submit-btn {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
    }
  }

  .OKE-Auth__content{
    flex: 1 1 auto;
    display: none;
    
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 45rem;
    padding: 0 6rem;
    padding-right: 0;

    @include media992(){
      display: flex;
    }

    .OKE-Auth__logo{
      margin-bottom: 2rem;
    }

    .OKE-Auth__headline{
      // max-width: 60rem;
    }
  }
  
}
