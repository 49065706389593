.kp-overlay-page{

  // .kp-contribute-configure__overlay-page-header{
  //   height: 9rem;
  //   z-index: 1000;
  //   position: relative;
  // }
  .overlayPageHeader{
    border-bottom: 0;
  }

  .kp-search-flow__container-fluid{
    padding-top: 0;
    background-color: $none;
    z-index: auto;
    position: relative;
    .kp-search-input__wrappper {
      // Overrides
      .kp-input-wrapper {
        height: 8rem;
        // background-color: $white;
        // border: 0.5px solid $greyColor15;
        // border-radius: 0.5rem;
        
        // @include ls-minus-01();
        .kp-text-input{
          @include titleSans--S();
        }
      }
      // .kp-text-input.large{
      //   height: 8rem;
      // }
    }
  }
}
