
  .kp-dynamic-tags-input{

    .kp-dynamic-search-input-wrapper{
      // margin-top: -0.7rem;

      .kp-dynamic-search-input{
        height: 4rem;
        padding: 0;
      }
    }

    .kp-selected-comps-group{
      margin-top: -0.7rem;
     
      .kp-tags-selected-comp{
        display: flex;
        align-items: center;
        padding: 0.3rem 0.7rem;
        margin: 0.7rem .5rem;
        // margin: 0.5rem 0.7rem;
        
        border-radius: 2rem;
        margin-top: 0.7rem;
        border: 1px solid $greyColor15;
        .kp-tags-selected-comp__text{
          padding: 0.5rem 1rem;
          // padding-left: 0.5rem;
        }
      }
    }

  }
