.kp-dynamic-profile-card-container{
  &.input-comp{
    // @include kp-dashed-border();
  }

  // margin: 0 -2rem;

  .kp-dynamic-profile-card-wrapper{
    display: flex;
    // flex-wrap: wrap;
    padding: 2rem;
    align-items: center;
    margin: 1rem 2rem;
    margin-top: 0rem;
    @include box-shadow-0();
    border: 0.5px solid $greyColor15;

    @include media768(){
      flex-wrap: nowrap;
    }

  }
}




  .kp-dynamic-profile-card-input__image-input-wrapper{
    // flex: 1 0 auto;
    .kp-dynamic-profile-card-input__image-input{
      width: 15rem;
      height: 15rem;
      margin-right: 0;
      margin-bottom: 2rem;

      @include media768(){
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }

  }

  .kp-dynamic-profile-card-input__text-content{

    width: 100%;

    

    .kp-dynamic-profile-card-input{
      // margin-bottom: 2rem;
      // min-height: 6rem;
      // padding: 0 2rem;

      &.display-comp{
        min-height: auto;
        margin-bottom: 1rem;
        .input{
          @include titleSerif--S();
        }
        

        &::after{
          display: none;
        }

        .kp-dynamic-search-input-wrapper{
          min-height: auto;
        }
      }



      .kp-dynamic-search-input-wrapper{
        // min-height: 6rem;

        .kp-dynamic-search-input{
          padding-left: 0;
          padding-right: 0;
          @include titleSans--S();
          // @include sans-serif();
          // @include h4-plus();
          // @include weight-bold();
          text-transform: capitalize;
        }
      }
    }

    .kp-dynamic-profile-card-input__desc{
      // padding-left: 2rem;
      @include kp-clamp-text(4);

      .kp-rich-editor{
        @include bodySans--M();
        
      }
    }
  }
