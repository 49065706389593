.Announcement{
  // height: 1.5rem;
  text-align: center;
  padding: 0 1rem;
  color: $white;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5000000;

  .Announcement__text{
    padding: 0.5rem 0;
  }

  .Announcement__action{
    margin-left: 1rem;
  }

  &.success{
    background-color: $green;
  }

  &.neutral{
    background-color: $yellow;
  }

  &.danger{
    background-color: $red;
  }

  &.-full-page-{
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
}