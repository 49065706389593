.SideScrollPageContainer{
    // @include mediaQuery--lessThan('lg'){
        // padding-right: 25% !important;
        overflow: hidden;
        position: relative;

        .pageRow{
            flex-wrap: nowrap;

            .oke-col{
                flex-shrink: 0;
                @include kp-transition(margin);
            }
        }

        .SideScrollPageContainer__scrollActions{
            display: flex;
            width: 9rem;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
        }

        .SideScrollPageContainer__slideIndicator{
            display: flex; 
            justify-content: center;

            .slideIndicatorIcon{
                padding-right: 1rem;

                &:last-of-type{
                    padding-right: 0;
                }
            }
        }

        // .EventsSidebar__cards-row{
        //     flex-wrap: nowrap;

        //     .Card-Wrapper--EventsSidebar{
        //         flex-shrink: 0;
        //         @include kp-transition(margin);
        //     }
        // }
    // }
}
