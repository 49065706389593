 .TableElem__Header,
 .TableElem__Row {
     min-height: 4rem;
     display: flex;
     align-items: center;
     padding-left: 1rem;
     padding-right: 1rem;
     border-radius: 2px;
     .TableElem__HeaderCell,
     .TableElem__RowCell {
         flex: 1 0 0; // the 0 on basis makes sure that all of them grow equally
         padding-left: 1rem;
         padding-right: 1rem;
     }
 }
 
 .TableElem__Header {
     background-color: $white;
     border-bottom: 2px solid #F5F5F5;
 }
 
 .TableElem__Row-Wrapper {
     // overflow: hidden;
     @include kp-transition(height);
     margin-top: 0.25rem;
     margin-bottom: 0.25rem;
 }
 
 .TableElem__Row {
     background-color: $greyColor5;
     //  border: 1px solid $greyColor5;
     &.-expandible- {
         @include kp-transition(background-color);
         cursor: pointer;
         &:hover {
             background-color: $white;
         }
     }
 }
 
 .TableElem__HeaderCell {
     display: flex;
     align-items: center;
     .TableElem__HeaderCellSortBtn {
         margin-left: 0.5rem;
         width: 2rem;
         height: 100%;
         cursor: pointer;
     }
 }
 
 .TableElem__RowCell {}