.kp-contribute-flow__primary-actions-wrapper{
  background-color: $white;
}

.kp-contribute-flow{
  background-color: $white;

  &.extra-light-grey-bg{
    @media (min-width: 768px){
      background-color: $greyColor5;
    }
  }

  .kp-contribute-configure__overlay-page-header-wrapper{
    height: 7rem;
    z-index: 1000;
    position: relative;
  }

  .kp-contribute-flow__container-fluid{
    padding-top: 2rem;
    @include media768(){ padding-top: 6rem}
    background-color: $none;
    z-index: auto;
    position: relative;
  }
}
