.kp-data-summary-card-wrapper{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .kp-data-summary-card{
    height: 100%;
    background-color: $white;
    border: 1px solid $greyColor15;
    @include box-shadow-1();
  }
}
