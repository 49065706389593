.OKE-Dropdown{
    
    .OKE-Dropdown__ref-wrapper{
        position: relative;
        user-select: none;

        .OKE-Dropdown__Select, .OKE-Dropdown__ComboboxSelect, .OKE-Dropdown__TagsSelect{
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            border-radius: 2px;
            height: 5rem;
            min-width: 36rem;
            background-color: $greyColor5;
            padding-left: 2rem;
            padding-right: 2rem;
            
            @include inputElements__hoverAndFocus();
          
            .OKE-Dropdown__optionsSelectedFeedback{
                flex: 1 0 auto;
            }
    
            .OKE-Dropdown__textInput{
                height: 100%;
                border: 0;
                width: 100%;
                background-color: $none;
                min-width: 30rem; //why is this even there?
                
                &:focus{
                    border: 0;
                }
    
                &::placeholder{
                    color: $greyColor40;
                }
            }
            
            .OKE-Dropdown__Caret{
                position: relative;
                padding-left: 1rem;
                display: flex;
                align-items: center;
                
    
                .OKE-Dropdown__CaretClickArea{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
            
        }

        .OKE-Dropdown__TagsSelect{
            // flex-wrap: wrap;
            height: auto;
            align-items: flex-start;

            .OKE-Dropdown__tags-wrapper{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: -0.5rem;
                height: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;

                .OKE-Dropdown__tag{
                    background-color: $primaryColor10;
                    margin: 0.5rem;
                    padding-left: 1rem;
                    display: flex;
                    align-items: center;
                    border-radius: 2px;
                    height: 3rem;

                    &:last-of-type{
                        margin-right: 1.5rem;
                    }

                    .OKE-Dropdown__tagContent{
                        display: flex;

                        .kp-image-block{
                            background-color: $white !important;
                        }
                    }

                    .OKE-Dropdown__tagText{
                        @include weight-semibold();
                        padding-right: 1rem;
                    }

                    .OKE-Dropdown__removeTagBtn{
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-bottom: 0.1rem;
                        background-color: $none;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        @include kp-transition(background-color);

                        &:hover{
                            background-color: $primaryColor40;
                        }
                    }
                }

                .OKE-Dropdown__textInput{
                    flex: 0 1;
                    height: 3rem;
                    margin: 0.5rem;
                }
            }

            .OKE-Dropdown__Caret{
                height: 5rem;
            }

            
        }

        .OKE-Dropdown__Options{
            position: absolute;
            z-index: 100000;
            padding-left: 0;
            cursor: pointer;
            @include box-shadow-1();
            width: 100%;
            min-width: max-content;
            min-width: -moz-max-content;
            max-width: 36rem;
            overflow: scroll;
            background-color: $white;
    
            &.-height-auto-{
                max-height: unset;
            }
    
            &.-height-large-{
                max-height: 33rem;
            }
    
            &.-height-medium-{
                max-height: 24rem;
            }
    
            &.-height-small-{
                max-height: 20rem;
            }
    
            .OKE-Dropdown__option{
                padding: 1rem 2rem;
                background-color: $white;
                @include kp-transition(background-color);
                // white-space: nowrap; //commenting this out might have bad effects...
                max-width: 36rem;

                .OKE-Dropdown__optionDesc{
                    @include kp-clamp-text(2);
                    white-space: normal;
                }

                .OKE-Dropdown__optionImg{
                    flex: 1 0 auto;
                }
    
                &:first-of-type{
                    margin-top: 1rem;
                }
    
                &:last-of-type{
                    margin-bottom: 1rem;
                }
    
                &:hover{
                    background-color: $primaryColor10;
                }
    
                &.-selected-{
                    background-color: $primaryColor10;
    
                    .OKE-Dropdown__optionText{    
                        @include weight-semibold();
                    }
                }

                .OKE-Dropdown__checkboxClickArea{
                    position: absolute;
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
    
}