
//temp mobile respnsiveness
.FilterModule{
    @include mediaQuery__lessThan('md'){
        .OKE-Filters__filters-row{
            display: block;
            margin-left: -2rem !important;
            margin-right: -2rem !important;
        }
        .OKE-Filters__filter-wrapper{
            border-right: none !important; 
        }
        .OKE-Dropdown{
            .kp-labels-wrapper{
                padding-left: 2rem !important;
            }
        }

        .OKE-Dropdown__Select{
            width: 100%;
            min-width: unset;
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }

        .FilterModule__searchAndSort-wrapper{
            display: block;

            .SearchFilter__textInput{
                width: 100%;
                padding-bottom: 2rem;
            }

            .SortElem{
                justify-content: flex-end;
                padding-bottom: 2rem;
            }
        }
    }
    
}