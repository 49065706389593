//common
.kp-data-card-body--headline, .kp-data-card-body--percent, .kp-data-card-body--percent-plus-viz{
  color: $greyColor100;
  padding: 2rem;

  @media ( min-width: 768px){
    padding: 3rem;
  }

  @media ( min-width: 1366px){
    padding: 4rem;
  }
}

.kp-data-molecule{
  display: flex;
  flex-direction: column;
  align-items: center;

  .kp-data-molecule__gauge-viz{
    max-width: 10rem;

    @media ( min-width: 576px){
      max-width: 12.5rem;
    }
  }
}

.kp-data-keyval-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -2.5rem;

  @media ( min-width: 768px){
    margin-top: -3.3rem;
  }
}

.kp-data-key{
  text-align: center;
}

//headline
.kp-data-card-headline{
  padding-bottom: 1rem;
  display: flex;
  align-items: baseline;


  .kp-data-card-headline__headline-part-2{
    padding-left: 1rem;
  }

  @media ( min-width: 768px){
    padding-bottom: 2rem;
  }
}

//percent
.kp-data-card-body--percent-plus-viz .kp-data-descrip{
  padding-bottom: 1.5rem;

  @media ( min-width: 768px){
    padding-bottom: 2.5rem;
  }
}

//percent plus viz
.kp-data-card-body--percent .kp-data-descrip{
  padding-bottom: 2rem;

  @media ( min-width: 768px){
    padding-bottom: 4rem;
  }
}
