//0 - 599 px : mobile : xs
//600 - 799 px : tab vertical | horizontal mob : sm
//800 - 999 px : tab hor : md
//1000 - 1299 px : large tab hor ? : lg
//1300 - 1599 px : laptop : xl
//1600 - above : desktop / monitors : xxl

$breakpoints : (
  xs: 0px,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1300px,
  xxl: 1600px
);

$columns :(
  xs: 6,
  sm: 6,
  md: 6,
  lg: 12,
  xl: 12,
  xxl: 12
);

$gutter : (
  xs: 2rem,
  sm: 2rem,
  md: 2rem,
  lg: 2rem,
  xl: 3rem,
  xxl: 3rem
);

$margins : (
  xs: 2rem,
  sm: 6rem,
  md: 6rem,
  lg: 6rem,
  xl: 6rem,
  xxl: 6rem
);

:export {
  xs_bp : map-get($breakpoints, 'xs');
  sm_bp : map-get($breakpoints, 'sm');
  md_bp : map-get($breakpoints, 'md');
  lg_bp : map-get($breakpoints, 'lg');
  xl_bp : map-get($breakpoints, 'xl');
  xxl_bp : map-get($breakpoints, 'xxl');
  xs_cols : map-get($columns, 'xs');
  sm_cols : map-get($columns, 'sm');
  md_cols : map-get($columns, 'md');
  lg_cols : map-get($columns, 'lg');
  xl_cols : map-get($columns, 'xl');
  xxl_cols : map-get($columns, 'xxl');

}