.OKE-Header{
  @include box-shadow-0();
  position: relative;
  z-index: 10000;

  .OKE-Header1{ 
    height: 6rem;

    .OKE-Header1-content{

      @include mediaQuery('lg'){
        padding-left: 6rem;
      }

      height: 100%;
      display: flex;
      justify-content: space-between;

      .OKE-Header1__module1{
        display: flex;
        align-items: center;
        
        .OKE-Header__hamburgerButton{
          @include headerIconButton();
        }

        .OKE-Header__logo{
          height: 4rem;
        }
        .OKE-Header__logoImage{
          height: 100%;
        }
      }

      .OKE-Header1__module2{
        display: flex;
        align-items: center;

        .OKE-Dropdown__ref-wrapper{
          height: 100%;

          .OKE-Dropdown__Select{
            min-width: unset;
          }
        }

        .OKE-Header__dropdown--secondary{
          height: 100%;
          .OKE-Dropdown{
            height: 100%;

            .OKE-Dropdown__Select-wrapper{
              height: 100%;
              .OKE-Dropdown__Select{
                height: 100%;
                background-color: aqua;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                @include headerLinkHover--secondary();

                &::after{ width: 0 }

                &:hover::after{ width: 0 }

                .OKE-Dropdown__SelectText{
                  @include headerLink--secondary();
                }
              }
            }

            .OKE-Dropdown__Options{
              overflow: auto;
            }

            .OKE-Dropdown__option{
              padding-left: 1.5rem;
              padding-right: 1.5rem;

              &:hover{
                background-color: $greyColor5;
              }
              &.selected{
                background-color: $greyColor5;
              }

              .OKE-Dropdown__optionText{
                @include headerLink--secondary();
              }
            }
          }
          
          
        }

        .OKE-Header__secondaryLink{
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 1.5rem; 
          padding-right: 1.5rem; 
          @include headerLinkHover--secondary();
          
          &.active{
            background-color: $greyColor5;
          }

          .OKE-Header__secondaryLink-link{
            display: flex;
            align-items: center;
            height: 100%;
          }
        }

        .OKE-Header__globalSearchLink{
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 1rem;
          padding-right: 1rem;
          @include headerLinkHover--secondary();

          @include mediaQuery('sm'){
            //this padding applies after Publish button shows up in header
            padding-left: 1.5rem; 
            padding-right: 2rem;
          }
        }

        .OKE-Header__publishButton{
          background-color: $greyColor100;
          border-radius: 2px;
          height: 4rem;
          display: flex;
          align-items: center;
          padding-left: 2rem;
          padding-right: 2rem;
          @include buttonText();
          color: $white;
          @include kp-transition(background-color);
          border: 0;

          &:hover{
            background-color: $black;
          }
        }

        .OKE-Header__notifications{
          height: 100%;
          position: relative;

          .OKE-Header__notificationsButton{
            @include headerIconButton();
            padding-left: 1rem;
            padding-right: 1rem;

            @include mediaQuery('sm'){
              padding-left: 2rem;
            }
          }

          .OKE-Header__notificationsList{
            position: absolute;
            right: 0;
            top: 10rem;
            z-index: 1;
          }
        }
        
        .OKE-Header__myAccountMenu{
          height: 100%;
          .OKE-Dropdown__Select-wrapper{
            height: 100%;
          }
          .OKE-Header__myAccountMenuButton{
            @include headerIconButton();
            padding-left: 1rem;
          }
        }

        .OKE-Header__loginLink{
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }

  .OKE-Header2{
    height: 4rem;
    display: flex;
    justify-content: center;
    border-top: 1px solid $greyColor10;
    
    .OKE-Header2__primaryLinks-wrapper{
      height: 100%;
      display: flex;
      justify-content: center;
    }
    
    .OKE-Header__primaryLink{
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      background-color: $none;
      @include kp-transition(background-color);

      &:hover{
        background-color: $primaryColor10;
      }

      &.active{
        background-color: $primaryColor10;
      }
      
      .OKE-Header__primaryLink-link{
        color: $primaryColorText;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .OKE-Header__primaryLinksKebabMenu{
      .OKE-Dropdown__ref-wrapper{
        height: 100%;
      }
      .OKE-Dropdown__Select-wrapper{
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
        background-color: $none;
        @include kp-transition(background-color);
        display: flex;
        align-items: center;
        height: 100%;

        &:hover{
          background-color: $primaryColor10;
        }
      }

      .OKE-Dropdown__optionText{
        @include headerLink--primary();
        color: $primaryColorText;
      }
      
    }

  }
}

//override on notification list positioning if secondary header is not there
.-six-six-{
  .OKE-Header__notifications{
    .OKE-Header__notificationsList{
      top: 6rem !important; 
    }
  }
}

