.LineChart__tooltip{
    background-color: $white;
    color: $greyColor100;
    width: 100%;
    padding: 1rem;

    .tooltip__row{
        display: flex;
        align-items: center;

        .tooltip_col{
            width: 50%;
            text-align: left;
        }
    }
}

.OKE-LineChart{
    position: relative;
    // display: inline-block;
    // position: relative;
    // width: 100%;
    // padding-bottom: 100%; /* aspect ratio */
    // vertical-align: top;
    // overflow: hidden;

    .svg-content-responsive {
        // display: inline-block;
        // position: absolute;
        // top: 10px;
        // left: 0;

        
    }

    .gridX, .gridY{
        
       &>.domain{
           stroke-width: 0;
       }

       &>.tick{

        &>line, &>text {
            stroke: $greyColor10;
         }

           &>line{
            stroke-dasharray: 2,2;
           }
           
        
       }
    }
}


