.ComparisonModule{
    .ComparisonModule__body-container{
        background-color: $white;

        .ComparisonModule__body{
            padding-left: 2rem;
            @include mediaQuery('md'){
                padding-left: 6rem;
            }
            position: relative;
            .ComparisonModule__vizBody{
                min-height: 800px;
                // background-color: red;
                padding-left: 23rem;

                .ComparisonModule__header{
                    padding: 2rem 0;
                }
                .ComparisonModule__vizBody-row{
                    margin-left: 0;
                    margin-right: 0;
                    position: relative;
                    // top: -0.3rem; //to give negative top
                    .ComparisonModule__vizBox-col{
                        min-width: 47rem;
                        padding: 0;
                        .ComparisonModule__vizBox{
                            // height: 30rem;
                            // background-color: aqua;
                            // background-color: $greyColor3;
                            border: 1px solid $greyColor5;
                            padding: 2rem;

                            .ComparisonModule__vizBoxContents{
                                height: 100%;
                                display: flex;
                                align-items: flex-end;

                                .ComparisonModule__viz-wrapper{
                                    flex-grow: 1;
                                }   
                            }
                        }
                    }
                }
                
                
            }
            .ComparisonModule__slotIndicator{
                // height: 30rem; // same as card height
                width: 0.5rem;
                border-radius: 2px;
                position: fixed;
                top: 0;
                left: 0;
                display: none;
                background-color: $primaryColor100;
                z-index: 1000000;
            }
            .ComparisonModule__sidebar{
                position: absolute;
                left: 0;
                top: 0;
                width: 23rem;
                height: 800px;
                padding-top: 2rem;
                margin-top: -2rem;
                margin-bottom: -2rem;

                .ComparisonModule__sidebarToggle{
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
                // background-color: blue;

            }
        }
    }
}