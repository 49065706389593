//3 cases where we explicitly set styling for content-blocks, using their block classname:
//- title
//- subtitle
//- summary

.OKE-Tpl.OKE-Tpl--Contribute {
  // .Tpl__introSection {
  //   // padding-bottom: 4rem;

  //   .Tpl__introSection-container {
  //     .Tpl__introSection-row {
  //       margin-bottom: 0;



  //       .Tpl__block:last-of-type {
  //         //last tpl block apply 4rem padding in conntribute tpl only
  //         padding-bottom: 6rem;

  //         .kp-image-input {
  //           //except for image input
  //           margin-bottom: -6rem;
  //         }
  //       }
  //       .kp-image-input {
  //         margin-top: 0;
  //       }
  //     }
  //     .kp-labels-wrapper {
  //       .sublabel {
  //         font-style: normal;
  //         font-size: 1.4rem;
  //         margin-top: 0.5rem;
  //         color: $greyColor15;
  //       }
  //     }
  //   }
  // }

  // .kp-dynamic-search-input {
  //   font-size: 1.6rem;
  // }

  // .Tpl__bodySection-container {
  //   .Tpl__block {

  // padding: 3rem 0 !important;
  // margin-bottom: 1rem !important;

  

  .kp-multi-profile-cards-container {
    position: relative;
    border: 1px dashed $greyColor15;
    .kp-dynamic-profile-card-container {
      .kp-dynamic-profile-card-wrapper {
        margin: 2rem 2rem 0 2rem !important;
      }
      .kp-dynamic-profile-card-input__image-input-wrapper {
        .kp-image-input__placeholder-block-content {
          background-color: white;
          // border: 1px dashed $primaryColor100;
          .kp-image-upload-btn-wrapper {
            border: none;
          }
        }
      }
      .kp-dynamic-search-input-wrapper {
        .kp-dynamic-search-input {
          // font-size: 2rem;
          // font-family: "noto_serif";
          @include titleSerif--S();
        }
      }
    }
    .kp-remove-profile-card__action-btn {
      top: 18%;
      right: 6%;
      z-index: 0;
    }
    .kp-btn {
      border: none;
      margin-bottom: 1rem;
      padding-bottom: 0;
      font-size: 1.6rem;
    }
  }

  //   //   }
  //   // }
  //   .kp-labels-wrapper {
  //     .sublabel {
  //       font-style: normal;
  //       font-size: 1.4rem;
  //       margin-top: 0.5rem;
  //       // color: $greyColor15;
  //     }
  //   }
  // }

  // .Tpl__tagSection {
  //   .kp-input-pseudo-wrapper {
  //     border-bottom: 1px solid $primaryColor100;
  //   }
  //   .kp-labels-wrapper {
  //     padding-bottom: 0.5rem;
  //     .sublabel {
  //       font-style: normal;
  //       font-size: 1.4rem;
  //       margin-top: 0.5rem;
  //       // color: $greyColor15;
  //     }
  //   }
  // }
}

.OKE-Tpl {
  .Tpl__introSection-1 {
    background-color: $greyColor100;
    padding-top: 6rem;
    padding-bottom: 6rem;

    .Tpl__introSection-1-container {
      @include pageContainer();
      overflow: unset;

      .Tpl__introSection-1-row {
        @include pageRow();
        margin-top: -1rem;
        margin-bottom: -1rem;

        .Tpl__block {
          @include oke-col-0();
          @include oke-col(10, "lg");
          @include oke-offset(1, "lg");
          @include oke-col(8, "xl");
          @include oke-offset(2, "xl");

          padding-top: 1rem;
          padding-bottom: 1rem;

          &.Tpl__block--title,
          &.Tpl__block--subtitle {
            text-align: center;
            color: $greyColor15;

            .kp-rich-editor {
              text-align: center;
              color: $greyColor15;

              span {
                @include weight-normal();
              }
              //make sure title and subtitle always have normal weight even if user might have made them bold using the rich text editor

              .public-DraftEditorPlaceholder-root {
                color: $greyColor15;
              }
            }
          }

          &.Tpl__block--title {
            @include titleSerif--L();
  
            .kp-rich-editor, .kp-text-input-display {
              @include titleSerif--L();  
            }
          }

          &.Tpl__block--subtitle {
            @include titleSans--S();
            padding-top: 0;
  
            .kp-rich-editor, .kp-text-input-display {
              @include titleSans--S();
            }
          }
        }

        //--
        .kp-meta-primary {
          display: flex;
          justify-content: center;

          .kp-meta-primary__text--value {
            color: $white;
          }
        }

        //--
        .kp-resources-block{
          display: flex;
          justify-content: center;
        }

        .kp-link-as-button-input {
          .button {
            margin: 0 auto;
          }
        }

        .kp-link-or-downloadable-input {
          color: $white;
        }
        .kp-input-pseudo-wrapper::before {
          border-radius: 0;
          border: 2px solid $primaryColor100;
          background-color: $greyColor15;
        }
        .kp-text-input {
          height: 4rem;
          text-align: center;
        }

      }
    }
  }

  .Tpl__introSection-2 {
    background-color: $greyColor100;

    

    .Tpl__introSection-2-container {
      @include pageContainer();
      overflow: unset;

      .Tpl__introSection-2-row {
        @include pageRow();

        .Tpl__block {
          @include oke-col-0();
          @include oke-col(10, "lg");
          @include oke-offset(1, "lg");
          @include oke-col(8, "xl");
          @include oke-offset(2, "xl");
        }

        .kp-image-input, .kp-video-embed {

          @include oke-negative-hor-margin("xs");
          @include oke-negative-hor-margin("sm");
          @include mediaQuery("md") {
            @include oke-negative-hor-margin-0();
          }
        }

        .kp-image-input{
          @include mediaQuery('md'){
            padding-bottom: 6rem;
          }
        }
      }
    }
  }

  .Tpl__headSection {
    background: $greyColor5;
    border-bottom: 1px solid $greyColor15;
    .Tpl__headSection-container {
      @include pageContainer();
      // @include oke-offset(1, "lg");
      // @include oke-offset(0, "xl");
      overflow: unset;
      padding-top: 6rem;
      padding-bottom: 6rem;

      .Tpl__headSection-row {
        @include pageRow();
        margin-top: -2rem;
        margin-bottom: -2rem;
        

        .templateHeadSection__content {
          width: 100%;
        }

        .Tpl__block {
          @include oke-col-0();
          padding-top: 2rem;
          padding-bottom: 2rem;
          
          &:first-of-type {
            @include oke-offset(1, "lg");
            @include oke-offset(2, "xl");
          }

          //ref for this : https://www.growingwiththeweb.com/2014/06/detecting-number-of-siblings-with-css.html
          /* one item */
          &:first-child:nth-last-child(1) {
            @include oke-col(10, "lg");
            @include oke-col(8, "xl");
          }

          /* two items */
          &:first-child:nth-last-child(2),
          &:first-child:nth-last-child(2) ~ div {
            @include oke-col(3, "md");
            @include oke-col(5, "lg");
            @include oke-col(4, "xl");
          }

          /* three items */
          &:first-child:nth-last-child(3),
          &:first-child:nth-last-child(3) ~ div {
            display: inline;
            @include oke-col(3, "lg");
          }

          /* four items */
          &:first-child:nth-last-child(4),
          &:first-child:nth-last-child(4) ~ div {
            display: inline;
            @include oke-col(2, "lg");
          }

          .DraftEditor-root {
            span {
              font-weight: normal !important;
            }
          }
        }
       
      }
    }
  }

  .Tpl__bodySection {
    // position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
    // margin-bottom: 4rem;
    // .kp-labels-wrapper {
    //   padding-bottom: 1rem;
    //   .sublabel {
    //     font-style: normal;
    //     font-size: 1.4rem;
    //     margin-top: 0.5rem;
    //     // color: $greyColor15;
    //   }
    // }
    .Tpl__subsection__{
      width: 100%;
    }

    .Tpl__bodySection-container {
      @include pageContainer();
      overflow: visible;
      .Tpl__bodySection-row {
        @include pageRow();
        margin-top: -3rem;
        margin-bottom: -3rem;

        .Tpl__block,
        .Tpl__subsectionTitleModule {
          // padding-top: 3rem;
          padding-top: 3rem;
          padding-bottom: 3rem;

          @include oke-col-0();
          @include oke-offset(1, "lg");
          @include oke-col(10, "lg");
          @include oke-offset(3, "xl");
          @include oke-col(6, "xl");

          &.conditional {
            background-color: $greyColor5;
          }

          &.blockError {
            background-color: $xLightRed;
          }

          // .kp-dynamic-search-input {
          //   font-size: 1.6rem;
          // }
        }

        .Tpl__subsectionTitleModule {
          padding-bottom: 0;
        }

        .Tpl__block--summary {

          &::after{
            content: '';
            display: block;
            width: 100%;
            border-bottom: 1px solid $greyColor15;  
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
          
          span {
            @include weight-normal();
            @include summary();
            font-size: 1.75rem;
          }
        }
        .Tpl__block.Tpl__block--summary:last-of-type{
          padding-bottom: 0;
          border: none;
        }
      }
    }
  }

  .Tpl__tagSection {

    background-color: $greyColor5;
    border-top: 1px solid $greyColor15;
    border-bottom: 1px solid $greyColor15;

    .Tpl__tagSection-container{
      @include pageContainer();
      overflow: unset;
      padding-top: 6rem;
      padding-bottom: 6rem;

      .Tpl__tagSection-row{
        @include pageRow();
        margin-top: -3rem;
        margin-bottom: -3rem;
      }
    }
    // .kp-labels-wrapper {
    //   padding-bottom: 1rem !important;
    //   // .sublabel {
    //   //   font-style: normal;
    //   //   font-size: 1.4rem;
    //   //   margin-top: 0.5rem;
    //   //   color: $greyColor15;
    //   // }
    // }

    .Tpl__block{
      // padding-top: 3rem;
      padding-top: 3rem;
      padding-bottom: 3rem;

      @include oke-col-0();
      @include oke-offset(1, "lg");
      @include oke-col(10, "lg");
      @include oke-offset(3, "xl");
      @include oke-col(6, "xl");

      &.conditional {
        background-color: $greyColor5;
      }

      &.blockError {
        background-color: $xLightRed;
      }

      // .kp-dynamic-search-input {
      //   font-size: 1.6rem;
      // }
    }

    // community tag
    // .kp-dynamic-platform-resource-input-container {
    //   margin-top: 1rem;
    //   margin-bottom: 4rem;
    //   margin-left: -0.6rem;
    //   .kp-dynamic-platform-resource--display {
    //     display: flex;
    //     .kp-dy-platform-resource__selected-comp {
    //       border: 1px solid #e1e1e1;
    //       border-radius: 2rem;
    //       padding-left: 0.7rem;
    //       margin-bottom: 1rem;
    //       margin-right: 1rem;
    //     }
    //   }
    // }
    // simple tag
    // .kp-dynamic-tags-input-wrapper {
    //   // temp fix
    //   margin-left: -0.6rem;

    //   .kp-tags-selected-comp {
    //     margin-left: 0;
    //     &__text {
    //       font-size: 1.4rem;
    //     }
    //   }
    // }
    // .kp-dynamic-search-input-container {
    //   margin-left: 0rem;
    //   .kp-dynamic-search-input{
    //     @include inputText()
    //   }
    // }

    // .kp-input-wrapper {
    //   border-bottom: 1px solid $primaryColor100;
    // }
  }
}
