$modalMaxHeight_D : 50rem;
$modalMaxHeight_M : 100vh;

.kp-modal-container {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  display: block;
  padding: 0;
  // overflow: scroll;

  .kp-modal-wrapper {
    .kp-modal {
      position: relative;
      background-color: $white;
      height: $modalMaxHeight_M;
      border: $greyColor15;
      overflow: hidden;
      .kp-modal__scroll-area{
        overflow-y: scroll;
        height: $modalMaxHeight_M;
      }
    }
  }
}

@include media768() {
  .kp-modal-container {
    background-color: $black-opacity80;
    padding: 2rem 6rem;
    display: flex;
    align-items: center;

    .kp-modal-wrapper {
      .kp-modal {
        height: $modalMaxHeight_D;
        // border-radius: 1rem;
        @include box-shadow( 4px, 20px, 0.6);
        .kp-modal__scroll-area{
          height: $modalMaxHeight_D;
        }
      }
    }
  }
}
