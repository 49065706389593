.kp-meta-block--2-wrapper{
  // padding-top: 3rem;
  // border-top: 1px solid $greyColor15;

  .kp-meta-block--2{
    display: block;
    margin: -1.5rem;

    @include media576(){
      display: flex;
    }

    .kp-meta-block--2__meta{
      padding: 1.5rem;

      // flex: 1 0 50%;
      @include kp-separator-top();

      &:first-of-type{
        @include kp-no-separator();
      }

      @include media576(){
        @include kp-no-separator();
        @include kp-separator-left();

        @include kp-dy-meta-elem-width();
      }

      .kp-meta-block--2__meta-text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
