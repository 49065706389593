.kp-table{
    

    .kp-table__cell{
        background-color: $white;
        padding: 0.5rem 1rem;
    
        .public-DraftEditor-content{
            font-size: 12px;
            @include sans-serif();
        }

        &.disabled-cell-style{
            background-color: $greyColor5;
        }
    }
    
    .kp-table__cell--header{
        background-color: $primaryColor10;
        .public-DraftEditor-content{
            font-weight: bold;
        }
    }
}
