$metaBlockHeight: 1.5rem;

.kp-meta-block{
  // margin-bottom: 1rem;
  padding: 2rem 0;
  // border-top: 1px solid $greyColor15;
  display: flex;
  justify-content: space-between;
}

.kp-meta-block-contents-wrapper{

  display: flex;
  align-items: center;
  // padding-top: 2rem;
  // margin-left: 1rem;
  // margin-right: -2rem;

  color: $greyColor70;

  // .kp-meta-block__meta-wrapper{
  //   // flex-grow: 1;
  //   max-width: 50%;
  //   padding: 0 2rem;
  //   height: $metaBlockHeight;
  //   display: flex;

  //   &:first-of-type{
  //     padding-left: 0;
  //   }

  //   &:last-of-type{
  //     padding-right: 0;
  //   }

    .kp-meta-block__meta-text{
      display: flex;
      align-items: center;
      // justify-content: center;
      text-transform: capitalize;
      color: $greyColor100;
      width: 100%;

      .kp-meta-block__meta-icon{
        // padding-right: 1rem;
        display: flex;
        align-items: center;
      }

      .kp-meta-block__meta-img{
        // margin-right: 1rem;
        flex: 1 0 auto;
      }

      .text{
        // line-height: $metaBlockHeight;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:last-of-type{
      border: 0;
    }
  }

  .kp-meta-block__separator{
    width: 0.3rem;
    height: 0.3rem;
    // border-radius: 50%;
    background-color: $greyColor100;
    margin: 0 1rem;
  }

