.kp-dynamic-search-input__results-group-container{
  position: relative;

  .kp-dynamic-search-input__results-group-wrapper{
    position: absolute;
    top: 0.5rem;
    background-color: $primaryColor10;
    border-radius: 1rem;
    z-index: 1000;
    padding: 0;
    // min-width: 40rem;
    max-height: 30rem;
    overflow: scroll;
    width: 100%;
    @include box-shadow-1();

    .kp-dynamic-search-input__results-group{
      padding: 2rem 0;

      .kp-dynamic-search-input__result-wrapper{
        padding: 1rem 2rem;
        cursor: pointer;
        @include kp-transition(background-color);
        background-color: $none;

        &:hover{ background-color: $primaryColor10 }
        &.selected{ background-color: $primaryColor10 }

        //#1 generic -- used for default genResultComp in dynamicSearchInput & in dynamicResourceInput
        .kp-dy-search-result{
          display: flex;
          align-items: center;

          .kp-dy-search-result__img{
            flex: 0 0 auto;
            margin-right: 1rem;
          }

          .kp-dy-search-result__text-group{
            .kp-dy-search-result__desc{
              @include kp-clamp-text(2);
            }
          }
        }
      }
    }
  }
}
