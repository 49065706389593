.kp-page-actions-bar{
  width: 100%;
  padding-top: 0;
  position: fixed;
  bottom: 10px;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include media768(){
    background-color: $white;
    @include box-shadow-1-invert();
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .kp-btn.primary{
    width: 100%;
    border-radius: 0;
    
    
    @include media768(){
      min-width: 20rem;
      width: auto;
      // border-radius: 1rem;
    }
  }
}
