//#1 card--story dimensions config


.kp-subscribe-card{
    // margin: 2rem;
    position: relative;
    background-color: $primaryColor10;
    overflow: hidden;
    @include kp-transition(all);

    @include media768(){
      // margin: 6rem;
      margin-top: 0;
    }

    .kp-subscribe-card-pane__left {
      padding: 6rem 3rem 6rem 3rem;
      
      //background-image: url('/static/media/rainfe1.f13ecc23.svg');
      background-repeat: no-repeat;
      background-position: 90% 0%;
      background-size: 40rem;
      @include media768(){
        padding: 10rem 6rem 10rem 6rem;
        background-size: 36rem;
      }
    }

    .kp-subscribe-card-pane__right{
      padding: 6rem 3rem 6rem 3rem;
      background-color: #F7EBC8;
      @include media768(){
        padding: 10rem 6rem 10rem 6rem;
      }
    }

    .kp-subscribe-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
    }

    h4{
      margin-bottom: 2rem;
    }

    .kp-btn-wrapper {
      justify-content: left;
    }
}
