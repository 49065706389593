.DataDb__OverlayModule{
    
    .OverlayModule__body{
        position: relative;

        .OverlayModule__sidebar{
            position: absolute;
            right: 0;
            top : 0;
            width: 30rem;
        }
    
        .OverlayModule__viz{
            padding-right: 30rem;
        }
    }
    
}