.kp-image-block{
  @include imageBlockPos();

  .kp-image-block__img{
    @include imageBlockContentPos();
  }

  .ImageBlock__placeholderInitial{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: $primaryColorText;
  }
}

.ImageBlock__editImageActions{
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
}

.ImageBlock__caption{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: $greyColor15;
  padding: 0.5rem 1rem;
  @include kp-transition(background-color);

  &:hover{
    background-color: $greyColor40
  }

  .kp-rich-editor{
    @include caption();
  }

  &.-read-only-{
    background-color: $white;  
    border-top: 1px solid $greyColor10;  
  }
}

.kp-image-block__mouse-event-detector{
  // z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
}

.kp-img-layout-grid{
  .ver-grid{
    width: 33.3333%;
    position: absolute;
    height: 100%;
    border-left: 1px solid white;
    border-right: 1px solid white;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .hor-grid{
    width: 100%;
    height : 33.3333%;
    position: absolute;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
