.kp-radio-cards-group{
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 0;
  list-style: none;
  @include kp-remove-ul-styling();

  .kp-radio-card-wrapper{
    border: 0;
    @include kp-separator-bottom();
    padding: 1rem 2rem;

    &.last-in-row{
      border: 0;
      @include kp-separator-bottom();
    }

    .kp-radio-card{
      position: relative;
      cursor: pointer;
      padding: 3rem 1rem;
      display: flex;
      @include kp-transition(box-shadow);

      &.-has-image-{
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-between;
      }
      &:not(.-no-hover-){
        &:hover{ 
          @include box-shadow-2();
  
          .radioInputElement:not(.selected){
            background-color: $primaryColor10;
          }
        }
      }
      
      &.selected{ outline: 2px solid  $primaryColor100;}

      .radioInputElement{
        position: absolute;
        left: 1rem;
        top: 1rem;
      }

      .kp-radio-card__icon-wrapper{
        display: flex;
        align-items: center;
        padding-right: 2rem;
      }

      .kp-radio-card__text-wrapper{
        // padding-top: 0;
        // padding-left: 2rem;

        .kp-radio-card__description{
          padding-top: 1rem;
        }
      }
    }
  }
}

//desktop
@include media768(){
  .kp-radio-cards-group{
    margin: 0;


    .kp-radio-card-wrapper{
      padding: 1rem;
      @include kp-separator-bottom();
      @include kp-separator-right();

      // &:last-of-type{
      //   @include kp-no-separator();
      //   @include kp-separator-right();
      // }

      &.last-in-row{ padding-right: 0; }
      &.first-in-row{ padding-left: 0; }
      &.first-row{ padding-top: 0; }
      &.last-row{ padding-bottom: 0; }

      .kp-radio-card{
        padding: 5rem;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .kp-radio-card__icon-wrapper{
          padding-bottom: 2rem;
          padding-right: 0;
        }

        .kp-radio-card__text-wrapper{
          // padding-top: 2rem;
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}
