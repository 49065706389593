.TableElem-Wrapper {
    overflow-x: scroll;
    // @include mediaQuery('md'){
    //     overflow-x: unset;
    // }
    .TableElem {
        min-width: map-get($breakpoints, "md");
    }
}

.TableElem__Row-Wrapper {
    .TableElem__Row {
        height: 6rem;
        background-color: $white;
    }
}

.TableElem__Row-Wrapper:nth-child(even) {
    .TableElem__Row {
        background-color: $greyColor5;
    }
}