.kp-no-resource-ph{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .kp-no-resource-ph__img-wrapper{
    padding-left: 4rem;
    padding-right: 4rem;
    // padding-top: 4rem;

    .kp-no-resource-ph__img{
      width: 100%;
      max-width: 45rem;
    }
  }

  .kp-no-resource-ph__action-btn{
    margin-top: 4rem;
  }

}
