.OKE-DataDb{
    background-color: $greyColor5;
    
    .primaryTabBar-wrapper{
        padding: 0;
        //overrides
        .TabBar{
            background-color: $none;

            .TabBar__tab-wrapper.-active-{
                background-color: $white;
            }
        }

    }

    .tooltip--bottomBar{
        
        .tooltip-contentsWrapper{
            
            .tooltip-row{
                margin: 0 -1rem;
                .tooltip-col{
                    padding: 0 1rem;
                    max-width: 17rem;

                }
            }
        }
    }

    

}