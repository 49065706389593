.accordion-container {
    margin-top: 1.5rem;
    // margin-bottom: 1rem;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid $greyColor15;
    padding-bottom: 1rem;
    .accordion__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    .accordion__actions {
        display: flex;
        padding-bottom: 2rem;
    }
    .accordionContent-wrapper {
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms ease-in;
    }
    // @include oke-negative-hor-margin('xs');
    // @include oke-negative-hor-margin('sm');
    // @include oke-negative-hor-margin('md');
    // @include oke-negative-hor-margin('lg');
    // &.accordion-closed{
    //   height: 5rem;
    // }
    // &.accordion-open{
    //   height: auto;
    // }

    
}