.kp-resources-block{
  display: flex;
  flex-wrap: wrap;

  .kp-resource-wrapper{
    position: relative;
    height: 15rem;
    width: 15rem;
    border: 1px solid $primaryColor100;
    overflow: hidden;
    display: flex;
    align-items: center;

    .kp-resource__actions-wrapper{
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .kp-resource__action{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
      }
    }
  }
}
