.kp-checkbox-input{
  display: flex;
  flex-wrap: wrap;

  &.-no-flex-{
    display: block;
  }
}

.kp-checkbox-input__option{
  display: flex;
  padding-right: 2rem;
  padding-bottom: 1rem;
  cursor: pointer;

  &:last-of-type{
    padding-right: 0;
  }

  &:hover .checkboxElement:not(.selected){
    background-color: $primaryColor10;
  }

  
}
