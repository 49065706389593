.tooltip--floating{
    padding: 1rem;
    background-color: $white;
    border: 1px solid $greyColor5;
}

.tooltip__title{
    padding-bottom: 0.5rem;
}

.datavizTooltip__dataPointContent{
    display: flex; 
    align-items: center;
    padding-bottom: 0.5rem;
    .datavizTooltip__dataPointColor{
        width: 1rem; 
        height: 1rem; 
        border-radius: 2px; 
        margin-right: 1rem;
    }
    .datavizTooltip__dataPointText{
        
    }
}