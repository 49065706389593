.kp-profile--view, .kp-profile--edit{
  &.kp-profile--view--userProfiles, &.kp-profile--edit--userProfiles{

    .kp-tpl-block--org-and-desig-display, {
      padding-top: 0;
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: center;

      // .kp-like-org-and-designation-container{
      //   max-width: 100%;
      //   .kp-dynamic-platform-resource-input-container{
      //     max-width: 100%;
      //     .kp-dy-selected-comp__text{
      //       max-width: 100%;
      //       .kp-link-wrapper{
      //         max-width: 100%;
      //         .kp-link{
      //           max-width: 100%;
      //           .kp-link-content{
      //             max-width: 100%;
      //
      //             .kp-link-content__text{
      //               @include kp-ellipsis()
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }




    }

    .kp-tpl-block--org-affiliations{

      //NOTE: this is very useful for clipping kpLink text if it overflows. could be handy somewhere or the other...
      // @include clip-dy-res-text();



    }
  }
}
