.kp-content-type-card-wrapper{
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  @include kp-transition(box-shadow);

  cursor: pointer;

  &:hover{ @include box-shadow-2() }

  &:active{ outline: 2px solid $primaryColor100;}

  .kp-content-type-card{
    width: 100%;

    .kp-content-type-card__img{
      width: 100%;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 2rem;
    }

    .kp-content-type-card__text_wrapper{
      padding-top: 3rem;
      padding-left: 2rem;
      padding-right: 2rem;

      .Card__subtitle{
        padding-top: 1rem;
      }
    }

    .kp-content-type-card__btn-wrapper{
      padding-top: 3rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;

      display: flex;
      justify-content: center;

      .kp-content-type-card__btn{
        width: 60%;
      }
    }

  }
}

@include media768(){
  // .kp-content-type-card-wrapper{
  //   padding: 2rem;
  // }
}
