
//dropdown
.kp-dd{
  width: 100%;
  user-select: none;
  position: relative;
  
}

//we use this class when we want the dropdown to be half container width BUT the label to still be full container width
//this only works for screen widths greater than 768px
.kp-dd-wrapper--half-container-width-dd{

  @include media768(){
    .kp-dd{
      width: 50%;
      padding-right: $halfGutter;

      .kp-dd__options-wrapper{
        padding-right: $halfGutter;
      }
    }
  }
}


//override these overrides when in modal. not elegant. but we'll clean it later
.kp-modal-bg .kp-modal{

  .kp-dd-wrapper--half-container-width-dd{
    .kp-dd{
      width: 100%;
      padding-right: 0;

      .kp-dd__options-wrapper{
        padding-right: 0;
      }
    }
  }

}
