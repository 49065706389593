.DataDb__OverviewModule{
    
    
        .OverviewModule__header{
            background-color: $white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $greyColor10;

            .OverviewModule__header-row{
                display: flex; 
                align-items: center; 
                justify-content: space-between;
            }

            .OverviewModule__viewsDropdownLabel{
                padding-right: 1rem;
            }

            .OverviewModule__viewsDropdown{
                .OKE-Dropdown__Select{
                    height: 4rem;
                }
            }
        }
        .OverviewModule__viz{
            // border-bottom: 1px solid $greyColor10;
            background-color: $white;

            .OverviewModule__viz-row{
                display: flex;
                margin-left: -4rem;
                margin-right: -4rem;

                .OverviewModule__viz-col{
                    padding: 1.5rem 4rem 1rem 4rem;
                    border-right: 1px solid $greyColor10;  

                    &:last-of-type{
                        border-right: none;
                    }
                }
            }
        }
   
}