
.OKE-404{
    text-align: center;
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    // position: fixed;
    // top:6rem;

    padding: 10rem 0 13rem 0;
    left: 0;
    z-index: 100000000000000000000;
}