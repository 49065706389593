.Home__Cover{
  overflow: hidden;
  position: relative;

  .kp-home-cover__img{

    position: absolute;
    left: 0;
    left: 0;
    opacity: 0.15;
    
    filter: grayscale(100%);
    
  }
}



.kp-page-cover{
  height: 45rem;
  padding-top: 0;
  padding-bottom: 0;
  // @include inner-shadow-1();
  &.kp-page-cover--with-bg-img{
    position: relative;
    background-position: center;
    background-size: cover;
    // opacity: 0.4;
    // background-color: black;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;

      @include media768(){
        opacity: 0
      }
    }

    &.light{ //light stands for light text. so sover should be dark
      &::before{
        background: $black;
      }
    }

    &.dark{
      ::before{
        background: $white;
      }
    }

    @include media768(){
      // opacity: 1;
    }
  }

  @include media576(){
    height: 55rem;
  }

  .kp-page-cover__contents-flex-wrapper{
    height: 100%;
    position: relative;

    .kp-page-cover__img-block{
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      left: 0;

      @include media992(){
        position: relative;

      }

      
    }

    .kp-page-cover__text-container{
      display: flex;
      align-items: center;

      .kp-page-cover__text-group{
        flex-grow: 1;

        @include media576(){
          padding-left: 4rem;
          padding-right: 4rem;
        }


        @include media992(){
          padding-left: 0;
          padding-right: 4rem;
        }

        .kp-page-cover__title_and_cta{
          // display: flex;
          // align-items: center;
          // justify-content: space-between;

          .kp-btn-wrapper{
            flex: 1 0 auto
          }
        }

        .kp-page-cover__title{
          width: 100%;
        }

        .kp-page-cover__desc{
          padding-top: 2rem;
          max-width: 61rem
        }

      }
    }
  }
}
