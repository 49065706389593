.kp-dynamic-platform-resource--display{
  margin: -0.5rem 0;
  display: flex;
  flex-wrap: wrap;

  @include media992(){
    display: block;
  }
}

.kp-dy-platform-resource__selected-comp{
  padding: 0.5rem 1rem 0.5rem 0;
  
}
