.AdminDb-container{
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tempTrdCardList{
  // margin-top: -1rem;
  // margin-bottom: -1rem;
}

.tempSyncNewTrdModule{
  margin-top: 2rem;
  padding: 2rem;
  border: 1px solid $greyColor15;
  
}

.tempTrdCard{
  border: 1px solid $greyColor15;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .trdCard__titleWrapper{
    display: flex;
    align-items: center;
  }

  .trdCard__action{
    margin-left: 1rem;
  }
}