.kp-notif-list-item{
  display: flex;
  // align-items: center;
  padding: 2rem;
  background-color: $white;
  @include kp-transition(background-color);

  &:hover{
    background-color: $white !important; //we need important for this to work, because we have written inline css in notifDropdown that sets bgColor depending on the isRead datapoint
  }

  .kp-notif-list-item__type-icon-container{
    background-color: $primaryColor10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    border-radius: 0.5rem;

    .kp-notif-list-item__type-icon{
      color: $white;
    }
  }

  .kp-notif-list-item__text-group{
    padding-left: 2rem;

    .kp-notif-list-item__date{
      color: $greyColor70;
    }

    .kp-notif-list-item__title{
      @include kp-clamp-text(1);
    }

    .kp-notif-list-item__desc{
      @include kp-clamp-text(3);
    }
  }
}
