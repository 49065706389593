.kp-dynamic-search-input-container{
  position: relative;
  // margin: 0 -1rem;
  // width: 100%;
  // align-self: flex-start;


  &.not-read-only{
    @include kp-text-input-hover();
    //override kp-text-input-hover
    &::after{ left: 0; }
    &:hover{ &::after{ width: 100% } }
    &.focussed{ &::after{ right: 0; left: auto; width: 0;} 
    }
  }

  .kp-dynamic-search-input-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    // overflow: hidden; //need overflow and border radius to mask the on-input-hover underline appropriately
    // border-radius: 1rem;
    // padding: 0 1rem;
    z-index: 2;
    min-height: 4rem;

    .kp-selected-comps-group{
      display: flex;
      flex-wrap: wrap;
    }

    //kp-text-input overrides
    .kp-dynamic-search-input{
      background-color: $none;
      flex: 1 0 auto;
      @include no-border();
      // @include weight-medium();
      // @include serif();
      // @include h4-serif();
      @include inputText();
      padding: 0 !important; //overiding inline kptextinput style. hence the important
      height: 4rem;

      .kp-input-pseudo-wrapper{
        &::after{ display: none }

        &::before{
          background-color: $none;
          @include no-border();
        }
      }

    }
  }
}


.kp-dy-search-selected-comp{
  display: flex;
  align-items: center;

  .kp-dy-selected-comp__img{
    flex: 0 0 auto;
    margin-right: 1rem;
  }

  .kp-selected__btn--rm-selected{
    // margin-left: 0.5rem;
  }
}
