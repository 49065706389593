.Card-Wrapper--EventsSidebar{
  
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $white;
  // @include mediaQuery('sm'){
  //   padding-top: 1.5rem;
  //   padding-bottom: 1.5rem;
  // }

  // @include mediaQuery('md'){
  //   padding-top: 3rem;
  //   padding-bottom: 3rem;
  // }
  
  .Card--EventsSidebar{
    // background-color: $greyColor5;
    // border: 2px solid $greyColor5;
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // height: 100%;
    cursor: pointer;
    // @include kp-multi-transition(background-color);

    &:hover{
      // background-color: $white;
      // color: $white;
      // @include box-shadow-2();
      // box-shadow: -8px 8px 1px $greyColor10;
      // transform: scale(1.015);

    }  


    .Card__label{
      
    } 

    .Card__date{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .Card__title{
      margin-bottom: 1rem;
    }

    .Card__action{
      // position: absolute;
      // top: 0;
      // right: 0;
    }
    
    .Card__metaBlock{

      .kp-meta-block__meta-text{
        color: $white;
      }
      
    }
   

  }
  
}
