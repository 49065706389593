.kp-dd__select{
  // padding: 0 2rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $primaryColor100;
  
  &.large{  height: 5rem }
  &.small{  
    height: 4rem;
     }

  .kp-dd__select-text{
    color: $greyColor100;
    padding-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover{
      color: $primaryColorText !important;
    }
  }

  .kp-icon{
    position: relative;
    display: flex;
    align-items: center;

    .icon-click-area-hack{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}
