

.kp-contribute-flow .kp-contribute-tpl__container-fluid{
  padding-top: 0;
  // @include media768(){
  //   padding: 6rem;
  //   padding-top: 0;
  // }
}
.kp-contribute-tpl__page-header{
  position: sticky;
  top: 0;
  z-index: 10000;
  background-color: white;

  .kp-contribute-flow__publish-btn{
    // width: 12rem;
    box-shadow: none;
    background-color: $primaryColor100 !important;
    color: white;
  }
}

// .kp-contribute-tpl__container-fluid{
//   padding: 0;
//
//   @media (min-width: 768px){
//     padding: 0 6rem;
//   }
// }

// .kp-contr-settings-btn{
//   padding: 0.9rem;
//   margin-right: 1.5rem;
// }
