.kp-link-wrapper{
  padding-top: 1rem;
  margin-left: -0.5rem; //cancel out the slight padding on thr link-href element which accomodates for the on hover
  margin-right: -0.5rem;

  &:first-of-type{
    padding-top: 0
  }

  .kp-link{
    display: inline-block;
    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 0.2rem;
    @include kp-transition(background-color);

    .kp-link-content{
      display: inline-flex;
      align-items: center;

      .kp-link-content__icon{
        padding-right: 1rem;
        display: flex;
        align-items: center;
      }

    }
  }
}

//type = primary
.kp-link-wrapper.primary{
  .kp-link{

    &:hover{ background-color: $greyColor80 }

    // .kp-link-content__text{ color: $white }
  }
}

//type = secondary
.kp-link-wrapper.secondary{
  .kp-link{

    &:hover{ background-color: $primaryColor10; }

    .kp-link-content__text{ color: $primaryColorText; }
  }
}

//type = tertiary
.kp-link-wrapper.tertiary{
  .kp-link{

    &:hover{ background-color: $primaryColor100;}

    .kp-link-content__text{ 
      color: $primaryColor10 !important; 
      @include titleSans__L(); 
      @include weight-normal() 
    } // used in hamburger menu
  }
}
