.TabBar{
  background-color: $white;
  z-index: 1000;

  .TabBar-container{
    height: 100%;
    
    .TabBar-row{
      display: flex;
      height: 100%; 

      .TabBar__tab-wrapper{
        height: 100%;
  
        .TabBar__tab{
          @include normalizeButton();
        }
      }
    }  
  } 
}

.TabBar.TabBar--style1{
  height: 5rem;
  box-shadow: 0 1px 0 $greyColor10; //we use box shadow instead of border-bottom cuz sometimes we want the parent container outside this component to overlap this border

  .TabBar-container{
    
    .TabBar-row{
      
      .TabBar__tab-wrapper{
        @include kp-transition(background-color);
        &:hover{
          background-color: $primaryColor10;
        }
        &.-active-{
          position: relative;
          background-color: $white;
  
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $primaryColor100;
          }
  
        }

        .TabBar__tab{
          height: 100%;
          padding-top: 1.5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          @include label--M();
        }
      }
    } 
  }
}

.TabBar.TabBar--style2{
  height: 4rem;

  .TabBar-container{
    
    .TabBar-row{
      
      .TabBar__tab-wrapper{
        @include kp-multi-transition(background-color);
        background-color: $greyColor5;

        &:first-of-type{
          border-radius: 2px 0 0 2px;
        }

        &:last-of-type{
          border-radius: 0 2px 2px 0;
        }

        &:hover{
          background-color: $greyColor10;
        }
        &.-active-{
          position: relative;
          background-color: $greyColor100;
          

          .TabBar__tab{
            color: $white;
          }
        }

        .TabBar__tab{
          height: 100%;
          padding-left: 2rem;
          padding-right: 2rem;
          @include kp-multi-transition(color);
          @include meta();
        }
      }
    } 
  }
}