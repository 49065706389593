.kp-meta-primary {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  margin-left: -0.5rem;
  margin-right: -0.5rem; // we have to use this negative margin technique, cuz on small screen, if the second word breaks to the next line we need ti to be properly left aligned

  .kp-meta-primary__icon {
    // padding: 0 0.5rem;
  }

  .kp-meta-primary__text { 
    padding: 0 0.5rem;

    &.kp-meta-primary__text--key {
      color: $greyColor70;
    }
    

    &.kp-meta-primary__text--value {
      @include kp-ellipsis();
      color: $primaryColorText;
    }
  }
}
