

.TemplatePublishedPage{
  position: relative;
 
}
 .scroll-highlight{
    position: fixed;
    top:0rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #e1e1e1;
    z-index: 1000000000;

    &--visible{
      height: 100%;
      background-color: #33a4ff;
    }
  }

.kp-published-page{

  .kp-published-page__container-fluid{
    padding-top: 0;
    // padding: 0;
    position: relative;

    // @include media768(){
    //   padding: 0 6rem;
    // }

    .kp-published-page__actions{
      position: absolute;
      right: 2rem;
      top: 2rem;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      z-index: 10;

      .kp-published-page__action-btn{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    .updatesContainer{
      background: $greyColor5;
      padding: 2rem 0;
      margin: 0 -2rem;

      @include media768(){
        margin: 0 -6rem;
      }

      .kp-tpl-sub-section{
        border-top: 1px solid $greyColor10;
      }

      .kp-tpl-block-wrapper{
        margin: 0;
        @include media768(){
          margin: 0;
        }
      }
    }

    .updatesContainer__shareUpdateBtn{
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
}
