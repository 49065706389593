.OverlayModule__header{
    border-bottom: 1px solid $greyColor10; 
    background-color: $white;

    .OverlayModule__header-row{
        display: flex;
        align-items: center;
        margin-left: -4rem;
        margin-right: -4rem;
        height: 6rem;

        .OverlayModule__primaryViewToggle-wrapper, .OverlayModule__secondaryViewsDropdown-wrapper{
            padding-left: 4rem;
            padding-right: 4rem;
        }

        .OverlayModule__secondaryViewsDropdown-wrapper{
            // padding-top: 1rem;
            // padding-bottom: 1rem;

            //overrides
            .OverlayModule__dateRangeDropdown{
                .OKE-Dropdown__Select{
                    height: 4rem;
                    min-width: 18rem;
                }
            }
            

            //overrides
            .OverlayModule__dateRangePicker{
                .DateRange-input-wrapper{
                    height: 4rem;

                    .Date-input-container{
                        height: 4rem;

                        .Date-input{
                            height: 4rem;
                        }
                    }
                }
            }
        }

        .OverlayModule__primaryViewToggle-wrapper{
            border-right: 1px solid $greyColor10;
            height: 100%;
            display: flex;
            align-items: center;
        }
        
    }


    
    .toggleButton__label1, .toggleButton__label2{
        @include bodySans--M();
        @include weight-normal();
        @include ls-0();
        text-transform: unset;
    }

    .OverlayModule__secondaryViewsDropdownLabel{
        margin-right: 1rem;
    }
    
}

.OverlayModule__subheader{

    .OverlayModule__title{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    // .OverlayModule__secondaryViews{
    //     padding-top: 1rem;
    //     padding-bottom: 1rem;
    //     display: flex; 
    //     align-items: center; 
    //     justify-content: space-between;    
    // }
}
