.kp-modal-header{
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  background-color: $greyColor100;
  // border-bottom: 1px solid $greyColor15;
  
  .kp-modal-header__content{
    flex: 1 0 auto;
  }
  
  .kp-modal-header__actions-group{
    flex: 0 0 auto;
    text-align: right;

  }
}
